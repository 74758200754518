import React from 'react'
import {Link} from 'gatsby'
import LogoLetters from '../svg/LogoLetters'
// import IconBurgerMenu from '../icons/IconBurgerMenu'
import './header.scss'
import MenuBar from '../Menu'
import MapMarker from '../icons/MapMarker'
import {useMediaQuery} from "react-responsive";

const Header = (props) => {
    // const { toggle, open } = props
    const isTabletOrDesktop = useMediaQuery({minDeviceWidth: 766})

    return (
        <nav className="navbar is-fixed-top is-spaced has-shadow" aria-label="main navigation">
            <div
                style={{
                    flexBasis: '40%',
                }}
                className="navbar-brand"
            >
                <Link className="navbar-item" to="/">
                    <LogoLetters
                        height={isTabletOrDesktop ? '100' : '50'}
                        fill="rgba(255,255,255,.85)"
                        alt="Blumenhaus Georg: Der Blumenladen Ihrer Wahl in Koeln"
                    />
                </Link>

                {props.location && props.location.pathname !== '/contact' && (
                    <Link
                        style={{
                            alignSelf: 'center',
                            marginRight: isTabletOrDesktop ? '2rem' : '1rem',
                            marginLeft: isTabletOrDesktop ? '2rem' : '1rem',
                            flexShrink: 1,
                            maxWidth: isTabletOrDesktop ? '40%' : '25%'
                        }}
                        id="contact-link"
                        className={`navbar-item button is-primary is-outlined is-inverted ${isTabletOrDesktop ? 'is-medium' : 'is-small'}`}
                        to="contact"
                    >
                              <span className="icon">
                                <MapMarker
                                    height="50"
                                    fill="rgba(255,255,255,.85)"
                                    alt="Blumenhaus Georg: Lage in Koeln"
                                />
                              </span>
                        <span className="has-text-grey-lighter">Finde uns</span>
                    </Link>
                )}

                {/*<div*/}
                {/*  style={{*/}
                {/*    marginLeft: 0,*/}
                {/*  }}*/}
                {/*  id="burger-container"*/}
                {/*  onClick={toggle}*/}
                {/*  role="button"*/}
                {/*  className="navbar-burger"*/}
                {/*  aria-label="menu"*/}
                {/*  aria-expanded="false"*/}
                {/*>*/}
                {/*  <IconBurgerMenu open={open} />*/}
                {/*</div>*/}
            </div>



            <div className="navbar-menu">
                <div className="navbar-end">
                    <div className="navbar-item">
                        <MenuBar/>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Header
