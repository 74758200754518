module.exports = {
  siteTitle: 'Blumenhaus Georg',
  shortTitle: 'BlumenGeorg', // Used for App manifest e.g. Mobile Home Screen
  siteTitleAlt: 'Blumenhaus Georg', // This allows an alternative site title for SEO schema.
  publisher: 'Blumenhaus Georg', // Organization name used for SEO schema
  siteDescription: 'Blumenladen Georg Koeln, Flower shop Georg in Cologne',
  siteUrl: 'https://blumengeorg.de', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  author: 'Andrey Kolybelnikov', // Author for RSS author segment and SEO schema
  authorUrl: 'https://github.com/akolybelnikov', // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: '@rockapi', // Change for Twitter Cards
  shareImageWidth: 900, // Change to the width of your default share image
  shareImageHeight: 600, // Change to the height of your default share image
  backgroundColor: '#663399', // Used for Offline Manifest
  themeColor: '#121212', // Used for Offline Manifest
  copyright: 'Copyright © 2018 Blumenhaus Georg', // Copyright string for the RSS feed
}
