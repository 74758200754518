import React from 'react'

const IconDeliveryCat = (props) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 500 500"
      width={props.width}
      height={props.height}
      aria-labelledby="title"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="-3351.38"
          y1="-142.96"
          x2="-3327.85"
          y2="-142.96"
          gradientTransform="matrix(1, 0.06, -0.06, 1, 3591.4, 408.71)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#665391" />
          <stop offset="1" stopColor="#4f4f4f" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="-6393.35"
          y1="54.1"
          x2="-6369.82"
          y2="54.1"
          gradientTransform="matrix(-1, 0.06, 0.06, 1, -6041.74, 408.71)"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <title id="title">Cat delivers on the bike</title>
      <path
        fill="#6b6a6a"
        d="M175.38,232.14c-1.63,0-40.08-.51-63.14-44.71-6.57-12.6-14.92-17.76-24.82-15.34C75,175.14,65.9,187.9,65.9,192.37a6.7,6.7,0,1,1-13.41,0c0-11.56,14.16-29,31.74-33.3,9.38-2.3,27-2.48,39.89,22.16,19.35,37.08,50.94,37.51,51.25,37.51a6.7,6.7,0,1,1,0,13.41Z"
      />
      <path
        fill="#9e9e9e"
        d="M250.89,116.86s-67,48.26-76,60.77-15.19,33.07-8,43.79,65.6,25.43,91.16,24.13c26.36-1.34,27.26-4.92,37.09-17.43s34.41-75.52,24.58-91.61S250.89,116.86,250.89,116.86Z"
      />
      <path
        fill="#464477"
        d="M317.9,134.55q6.55,9.75,12.87,19.64t12.32,20c4,6.73,7.87,13.55,11.38,20.57a80.67,80.67,0,0,1,4.64,10.85,17.83,17.83,0,0,1,.69,2.94,11.36,11.36,0,0,1-.11,3.05,14.19,14.19,0,0,1-.75,2.94,6.14,6.14,0,0,1-1.76,2.61,6,6,0,0,1-6,.92,6.11,6.11,0,0,1-2.55-1.86,6.17,6.17,0,0,1-1.26-2.89,29.68,29.68,0,0,0-3.85-10.82,24.22,24.22,0,0,0-3.71-4.38c-1.38-1.37-2.81-2.69-4.28-4-2.92-2.58-5.93-5.09-8.95-7.57-6.06-4.95-12.23-9.75-18.45-14.49s-12.45-9.48-18.76-14.12q9.6,6.76,19.05,13.72c6.28,4.67,12.52,9.4,18.64,14.29,3.06,2.44,6.09,4.93,9,7.52,1.47,1.29,2.93,2.62,4.33,4a25,25,0,0,1,3.87,4.54,30.62,30.62,0,0,1,4,11.14,5.12,5.12,0,0,0,8.25,3.21c1.37-1.13,1.87-3.09,2.2-4.93a10.44,10.44,0,0,0,.12-2.79,16.94,16.94,0,0,0-.64-2.77,79.91,79.91,0,0,0-4.54-10.73c-3.46-7-7.33-13.8-11.22-20.58s-8-13.47-12.07-20.13S322.12,141.14,317.9,134.55Z"
      />
      <path
        fill="#9e9e9e"
        d="M317.9,134.55s42,63.54,41.47,75.15a14.55,14.55,0,0,1-1,5c-2.13,5.12-9.65,4-10.53-1.45a30.05,30.05,0,0,0-4.19-11.39c-5.86-9.2-55.62-43.45-55.62-43.45S282.45,126.76,317.9,134.55Z"
      />
      <path
        fill="#004b24"
        d="M350.8,203.59a.34.34,0,0,1,.46-.13,47.84,47.84,0,0,1,27.31,35.07.31.31,0,1,1-.61.11A47.22,47.22,0,0,0,351,204,.34.34,0,0,1,350.8,203.59Z"
      />
      <path
        fill="#004b24"
        d="M373.24,234a.87.87,0,0,0,.69.11c.58-.18.82-1,1.07-2.28.16-.78.32-1.67.58-2.54a.39.39,0,0,0-.19-.45h0a.28.28,0,0,0-.4.15,11.77,11.77,0,0,1-1.22,2.18c-.65,1-1.14,1.62-1,2.21A.94.94,0,0,0,373.24,234Z"
      />
      <path
        fill="#004b24"
        d="M371.16,228.63a.87.87,0,0,0,.7,0c.56-.25.67-1.14.77-2.39.06-.79.1-1.69.24-2.58a.38.38,0,0,0-.25-.41h0a.29.29,0,0,0-.38.2,11.72,11.72,0,0,1-.93,2.31c-.52,1-.92,1.75-.71,2.32A.93.93,0,0,0,371.16,228.63Z"
      />
      <path
        fill="#004b24"
        d="M368.39,223.58a.88.88,0,0,0,.69-.08c.52-.32.52-1.21.45-2.46,0-.79-.12-1.68-.09-2.58a.38.38,0,0,0-.3-.38h0a.29.29,0,0,0-.35.25,11.72,11.72,0,0,1-.62,2.41c-.38,1.09-.68,1.86-.4,2.38A.92.92,0,0,0,368.39,223.58Z"
      />
      <path
        fill="#004b24"
        d="M365,218.94a.88.88,0,0,0,.68-.17c.47-.39.36-1.27.12-2.5-.15-.77-.34-1.65-.43-2.55a.38.38,0,0,0-.35-.34h0a.28.28,0,0,0-.31.29,11.69,11.69,0,0,1-.29,2.47c-.23,1.13-.43,1.93-.09,2.42A.92.92,0,0,0,365,218.94Z"
      />
      <path
        fill="#004b24"
        d="M361,214.78a.87.87,0,0,0,.65-.25c.42-.45.19-1.31-.21-2.5-.25-.75-.56-1.6-.77-2.48a.39.39,0,0,0-.39-.29h0a.28.28,0,0,0-.27.33,11.61,11.61,0,0,1,0,2.49c-.08,1.16-.17,2,.24,2.41A.93.93,0,0,0,361,214.78Z"
      />
      <path
        fill="#004b24"
        d="M381.49,235.13a.81.81,0,0,1-.53.5c-.6.14-1.24-.61-2.17-1.56a12.88,12.88,0,0,0-2-1.75.4.4,0,0,1-.14-.47v0a.29.29,0,0,1,.17-.17.28.28,0,0,1,.23,0,24.41,24.41,0,0,0,2.35,1c1.12.45,1.93.82,2.16,1.5A1.19,1.19,0,0,1,381.49,235.13Z"
      />
      <path
        fill="#004b24"
        d="M379.46,228.59a.81.81,0,0,1-.46.56c-.57.22-1.31-.43-2.35-1.24a12.78,12.78,0,0,0-2.22-1.45.39.39,0,0,1-.2-.44v0a.29.29,0,0,1,.15-.19.29.29,0,0,1,.23,0,24.13,24.13,0,0,0,2.46.69c1.17.29,2,.54,2.34,1.18A1.17,1.17,0,0,1,379.46,228.59Z"
      />
      <path
        fill="#004b24"
        d="M376.61,222.42a.82.82,0,0,1-.38.61c-.54.29-1.35-.25-2.49-.9a12.8,12.8,0,0,0-2.39-1.14.39.39,0,0,1-.26-.41v0a.29.29,0,0,1,.12-.21.29.29,0,0,1,.22,0,23.93,23.93,0,0,0,2.53.36c1.19.13,2.06.27,2.47.86A1.16,1.16,0,0,1,376.61,222.42Z"
      />
      <path
        fill="#004b24"
        d="M373,216.71a.81.81,0,0,1-.3.65c-.5.36-1.37-.06-2.58-.57a12.81,12.81,0,0,0-2.51-.81.39.39,0,0,1-.31-.37s0,0,0,0a.29.29,0,0,1,.1-.22.29.29,0,0,1,.22-.07,23.8,23.8,0,0,0,2.55,0c1.2,0,2.08,0,2.56.52A1.16,1.16,0,0,1,373,216.71Z"
      />
      <path
        fill="#004b24"
        d="M368.66,211.52a.81.81,0,0,1-.21.69c-.45.42-1.37.11-2.64-.23a12.86,12.86,0,0,0-2.6-.48.39.39,0,0,1-.36-.33v0a.29.29,0,0,1,.07-.23.28.28,0,0,1,.21-.1,23.74,23.74,0,0,0,2.54-.31c1.19-.18,2.06-.28,2.61.19A1.16,1.16,0,0,1,368.66,211.52Z"
      />
      <path
        fill="#dfe22a"
        d="M377.28,239.53a.7.7,0,0,0,.79.6.6.6,0,0,1,1-.19.79.79,0,0,0-.17-1.52v0a.69.69,0,0,0-.81-.58.73.73,0,0,0-.46.89h0A.75.75,0,0,0,377.28,239.53Z"
      />
      <path
        fill="#dfe22a"
        d="M374.67,230a.69.69,0,0,0,.89.4.6.6,0,0,1,.93-.39.77.77,0,0,0-.47-1.42v0a.69.69,0,0,0-.91-.39.72.72,0,0,0-.27,1h0A.74.74,0,0,0,374.67,230Z"
      />
      <path
        fill="#dfe22a"
        d="M368.74,219.36a.69.69,0,0,0,1,.15.61.61,0,0,1,.79-.62.76.76,0,0,0-.82-1.24v0a.69.69,0,0,0-1-.13.71.71,0,0,0,0,1h0A.73.73,0,0,0,368.74,219.36Z"
      />
      <path
        fill="#dfe22a"
        d="M360.29,210.64a.69.69,0,0,0,1-.1.6.6,0,0,1,.6-.8.77.77,0,0,0-1.12-1h0a.69.69,0,0,0-1,.12.72.72,0,0,0,.25,1h0A.74.74,0,0,0,360.29,210.64Z"
      />
      <path
        fill="#004b24"
        d="M363.74,216.57a.34.34,0,0,1,.12-.46,47.84,47.84,0,0,1,44.13-5.3.31.31,0,1,1-.22.58,47.23,47.23,0,0,0-43.56,5.23A.34.34,0,0,1,363.74,216.57Z"
      />
      <path
        fill="#004b24"
        d="M401.41,213.07a.87.87,0,0,0,.45-.53c.15-.59-.47-1.24-1.4-2.09-.58-.54-1.27-1.13-1.87-1.8a.39.39,0,0,0-.48-.07h0a.28.28,0,0,0-.08.42,11.76,11.76,0,0,1,1.24,2.17c.49,1.05.8,1.81,1.38,2A.94.94,0,0,0,401.41,213.07Z"
      />
      <path
        fill="#004b24"
        d="M395.7,212.06a.87.87,0,0,0,.37-.59c.07-.61-.63-1.16-1.65-1.89-.64-.46-1.39-1-2.08-1.54a.38.38,0,0,0-.48,0h0a.29.29,0,0,0,0,.43,11.72,11.72,0,0,1,1.5,2c.62,1,1,1.69,1.62,1.8A.93.93,0,0,0,395.7,212.06Z"
      />
      <path
        fill="#004b24"
        d="M389.94,211.82a.88.88,0,0,0,.29-.63c0-.61-.77-1.07-1.88-1.66-.7-.37-1.5-.76-2.26-1.25a.38.38,0,0,0-.48.06v0a.29.29,0,0,0,0,.43,11.74,11.74,0,0,1,1.75,1.77c.74.89,1.24,1.54,1.83,1.58A.92.92,0,0,0,389.94,211.82Z"
      />
      <path
        fill="#004b24"
        d="M384.22,212.33a.88.88,0,0,0,.21-.67c-.09-.6-.9-1-2.08-1.4-.74-.27-1.59-.56-2.41-.94a.38.38,0,0,0-.47.12v0a.29.29,0,0,0,0,.24.29.29,0,0,0,.14.18,11.69,11.69,0,0,1,2,1.53c.85.78,1.43,1.37,2,1.32A.92.92,0,0,0,384.22,212.33Z"
      />
      <path
        fill="#004b24"
        d="M378.6,213.6a.87.87,0,0,0,.12-.69c-.17-.59-1-.83-2.25-1.11-.77-.17-1.66-.34-2.52-.62a.39.39,0,0,0-.45.19v0a.28.28,0,0,0,.15.4,11.59,11.59,0,0,1,2.16,1.25c1,.66,1.6,1.16,2.19,1A.92.92,0,0,0,378.6,213.6Z"
      />
      <path
        fill="#004b24"
        d="M406.6,206.56a.81.81,0,0,1,.15.71c-.19.58-1.16.75-2.46,1.05a12.89,12.89,0,0,0-2.54.82.4.4,0,0,1-.48-.12v0a.29.29,0,0,1-.05-.24.28.28,0,0,1,.14-.18,24.37,24.37,0,0,0,2.09-1.49c1-.73,1.7-1.23,2.4-1.08A1.19,1.19,0,0,1,406.6,206.56Z"
      />
      <path
        fill="#004b24"
        d="M399.94,204.92a.81.81,0,0,1,.24.68c-.11.6-1,.9-2.27,1.38a12.77,12.77,0,0,0-2.39,1.15.39.39,0,0,1-.48-.05h0a.29.29,0,0,1-.09-.23.29.29,0,0,1,.11-.2,24.05,24.05,0,0,0,1.86-1.75c.85-.85,1.5-1.44,2.22-1.39A1.17,1.17,0,0,1,399.94,204.92Z"
      />
      <path
        fill="#004b24"
        d="M393.19,204.18a.81.81,0,0,1,.33.64c0,.61-.91,1-2.06,1.66a12.82,12.82,0,0,0-2.2,1.46.39.39,0,0,1-.48,0h0a.29.29,0,0,1-.11-.21.29.29,0,0,1,.08-.21,23.89,23.89,0,0,0,1.61-2c.73-1,1.29-1.63,2-1.67A1.16,1.16,0,0,1,393.19,204.18Z"
      />
      <path
        fill="#004b24"
        d="M386.43,204.33a.81.81,0,0,1,.41.59c0,.61-.76,1.14-1.82,1.92a12.8,12.8,0,0,0-2,1.73.39.39,0,0,1-.48.07h0a.28.28,0,0,1-.09-.42,23.75,23.75,0,0,0,1.33-2.18c.6-1,1.07-1.79,1.77-1.92A1.16,1.16,0,0,1,386.43,204.33Z"
      />
      <path
        fill="#004b24"
        d="M379.75,205.37a.81.81,0,0,1,.48.54c.13.6-.61,1.23-1.55,2.14a12.86,12.86,0,0,0-1.75,2,.39.39,0,0,1-.47.14h0a.29.29,0,0,1-.17-.18.28.28,0,0,1,0-.23,23.78,23.78,0,0,0,1-2.33c.46-1.11.83-1.91,1.5-2.14A1.15,1.15,0,0,1,379.75,205.37Z"
      />
      <path
        fill="#dfe22a"
        d="M408.19,212.44a.7.7,0,0,0,.92-.37.6.6,0,0,1,.35-.94.79.79,0,0,0-1.39-.64h0a.69.69,0,0,0-.92.39.73.73,0,0,0,.53.85h0A.75.75,0,0,0,408.19,212.44Z"
      />
      <path
        fill="#dfe22a"
        d="M398.71,209.78a.69.69,0,0,0,.8-.55.6.6,0,0,1,.14-1,.77.77,0,0,0-1.46-.33h0a.69.69,0,0,0-.8.58.72.72,0,0,0,.68.72h0A.74.74,0,0,0,398.71,209.78Z"
      />
      <path
        fill="#dfe22a"
        d="M386.51,209.34a.69.69,0,0,0,.62-.74.61.61,0,0,1-.12-1,.76.76,0,0,0-1.48.06h0a.69.69,0,0,0-.62.77.71.71,0,0,0,.84.52h0A.73.73,0,0,0,386.51,209.34Z"
      />
      <path
        fill="#dfe22a"
        d="M374.68,212.09a.69.69,0,0,0,.41-.88.6.6,0,0,1-.38-.93.77.77,0,0,0-1.43.45h0a.69.69,0,0,0-.4.9.72.72,0,0,0,1,.28h0A.74.74,0,0,0,374.68,212.09Z"
      />
      <path
        fill="#004b24"
        d="M372.91,206.92a.34.34,0,0,0-.4-.26,47.85,47.85,0,0,0-36.43,25.46.31.31,0,1,0,.55.29,47.23,47.23,0,0,1,36-25.13A.34.34,0,0,0,372.91,206.92Z"
      />
      <path
        fill="#004b24"
        d="M342.49,229.41a.87.87,0,0,1-.69-.1c-.51-.34-.47-1.24-.35-2.5.08-.79.19-1.69.2-2.59a.39.39,0,0,1,.32-.37h0a.29.29,0,0,1,.23.06.29.29,0,0,1,.1.2,11.75,11.75,0,0,0,.52,2.44c.34,1.11.61,1.89.31,2.41A.94.94,0,0,1,342.49,229.41Z"
      />
      <path
        fill="#004b24"
        d="M346.08,224.85a.87.87,0,0,1-.67-.19c-.46-.4-.31-1.28,0-2.51.18-.77.4-1.64.53-2.53a.38.38,0,0,1,.36-.32h0a.29.29,0,0,1,.3.31,11.72,11.72,0,0,0,.2,2.48c.19,1.14.36,1.95,0,2.42A.93.93,0,0,1,346.08,224.85Z"
      />
      <path
        fill="#004b24"
        d="M350.21,220.84a.88.88,0,0,1-.64-.28c-.4-.46-.14-1.31.3-2.49.27-.74.61-1.57.85-2.44a.38.38,0,0,1,.4-.27h0a.28.28,0,0,1,.26.34,11.71,11.71,0,0,0-.12,2.48c0,1.16.11,2-.32,2.4A.92.92,0,0,1,350.21,220.84Z"
      />
      <path
        fill="#004b24"
        d="M354.82,217.4a.88.88,0,0,1-.6-.36c-.34-.51,0-1.32.62-2.43.37-.7.81-1.48,1.16-2.31a.38.38,0,0,1,.43-.22h0a.28.28,0,0,1,.21.37,11.7,11.7,0,0,0-.45,2.45c-.11,1.15-.15,2-.63,2.34A.92.92,0,0,1,354.82,217.4Z"
      />
      <path
        fill="#004b24"
        d="M359.85,214.6a.87.87,0,0,1-.55-.43c-.27-.55.21-1.3.94-2.33.46-.64,1-1.36,1.47-2.14a.39.39,0,0,1,.46-.16h0a.28.28,0,0,1,.16.4,11.62,11.62,0,0,0-.77,2.37c-.27,1.13-.42,1.94-.94,2.24A.93.93,0,0,1,359.85,214.6Z"
      />
      <path
        fill="#004b24"
        d="M334.29,228a.81.81,0,0,0,.36.63c.53.31,1.37-.22,2.53-.85a12.84,12.84,0,0,1,2.44-1.08.4.4,0,0,0,.28-.41v0a.29.29,0,0,0-.12-.21.28.28,0,0,0-.22,0,24.5,24.5,0,0,1-2.55.29c-1.2.1-2.08.21-2.51.8A1.19,1.19,0,0,0,334.29,228Z"
      />
      <path
        fill="#004b24"
        d="M338.15,222.36a.81.81,0,0,0,.27.67c.49.37,1.38,0,2.61-.49a12.82,12.82,0,0,1,2.55-.73.39.39,0,0,0,.32-.36v0a.28.28,0,0,0-.3-.3,24,24,0,0,1-2.56-.07c-1.2-.07-2.08-.07-2.58.44A1.18,1.18,0,0,0,338.15,222.36Z"
      />
      <path
        fill="#004b24"
        d="M342.7,217.31a.81.81,0,0,0,.19.7c.43.44,1.36.16,2.64-.13a12.8,12.8,0,0,1,2.62-.38.39.39,0,0,0,.37-.31v0a.29.29,0,0,0-.06-.24.29.29,0,0,0-.2-.1,23.9,23.9,0,0,1-2.52-.41c-1.18-.22-2.05-.35-2.61.09A1.16,1.16,0,0,0,342.7,217.31Z"
      />
      <path
        fill="#004b24"
        d="M347.84,212.92a.81.81,0,0,0,.09.71c.37.49,1.33.34,2.63.22a12.81,12.81,0,0,1,2.64,0,.39.39,0,0,0,.41-.26v0a.29.29,0,0,0,0-.24.29.29,0,0,0-.19-.13,23.89,23.89,0,0,1-2.44-.73c-1.14-.38-2-.62-2.6-.25A1.15,1.15,0,0,0,347.84,212.92Z"
      />
      <path
        fill="#004b24"
        d="M353.51,209.24a.81.81,0,0,0,0,.72c.3.53,1.27.51,2.59.56a12.85,12.85,0,0,1,2.63.31.39.39,0,0,0,.44-.21v0a.29.29,0,0,0,0-.24.28.28,0,0,0-.17-.15,23.73,23.73,0,0,1-2.33-1c-1.08-.52-1.89-.87-2.54-.59A1.15,1.15,0,0,0,353.51,209.24Z"
      />
      <path
        fill="#dfe22a"
        d="M337,233.46a.7.7,0,0,1-.93.34.6.6,0,0,0-.89-.47.79.79,0,0,1,.61-1.4v0a.69.69,0,0,1,.94-.32.73.73,0,0,1,.18,1h0A.75.75,0,0,1,337,233.46Z"
      />
      <path
        fill="#dfe22a"
        d="M342.31,225.16a.69.69,0,0,1-1,.12.6.6,0,0,0-.77-.65.77.77,0,0,1,.87-1.22v0a.69.69,0,0,1,1-.1.72.72,0,0,1,0,1h0A.74.74,0,0,1,342.31,225.16Z"
      />
      <path
        fill="#dfe22a"
        d="M351.12,216.71a.69.69,0,0,1-1-.14.61.61,0,0,0-.58-.83.76.76,0,0,1,1.15-.94h0a.69.69,0,0,1,1,.16.71.71,0,0,1-.28.95h0A.73.73,0,0,1,351.12,216.71Z"
      />
      <path
        fill="#dfe22a"
        d="M361.77,210.86a.69.69,0,0,1-.9-.38.6.6,0,0,0-.34-.95.77.77,0,0,1,1.36-.62h0a.69.69,0,0,1,.9.4.72.72,0,0,1-.52.85h0A.74.74,0,0,1,361.77,210.86Z"
      />
      <path
        fill="#004b24"
        d="M353.21,213.39a.34.34,0,0,0,0-.48,47.84,47.84,0,0,0-40.61-18.08.31.31,0,1,0,0,.62,47.23,47.23,0,0,1,40.08,17.84A.34.34,0,0,0,353.21,213.39Z"
      />
      <path
        fill="#004b24"
        d="M318.25,198.93a.87.87,0,0,1-.27-.64c0-.61.82-1,2-1.59.72-.34,1.54-.71,2.32-1.17a.39.39,0,0,1,.48.08v0a.28.28,0,0,1-.05.42,11.74,11.74,0,0,0-1.82,1.71c-.78.86-1.3,1.5-1.91,1.51A.94.94,0,0,1,318.25,198.93Z"
      />
      <path
        fill="#004b24"
        d="M324,199.65a.87.87,0,0,1-.18-.67c.11-.6.94-.93,2.14-1.32.75-.25,1.61-.5,2.44-.86a.38.38,0,0,1,.46.14v0a.28.28,0,0,1-.11.41,11.76,11.76,0,0,0-2,1.45c-.88.75-1.48,1.31-2.08,1.25A.93.93,0,0,1,324,199.65Z"
      />
      <path
        fill="#004b24"
        d="M329.57,201.12a.87.87,0,0,1-.09-.69c.19-.58,1.05-.8,2.28-1,.77-.15,1.66-.29,2.53-.53a.38.38,0,0,1,.44.2v0a.29.29,0,0,1-.16.4,11.73,11.73,0,0,0-2.19,1.18c-1,.63-1.64,1.11-2.22,1A.92.92,0,0,1,329.57,201.12Z"
      />
      <path
        fill="#004b24"
        d="M334.89,203.3a.88.88,0,0,1,0-.7c.26-.55,1.15-.65,2.4-.72.79,0,1.69-.07,2.58-.19a.38.38,0,0,1,.41.26v0a.29.29,0,0,1-.21.37,11.73,11.73,0,0,0-2.33.88c-1,.5-1.77.89-2.33.67A.92.92,0,0,1,334.89,203.3Z"
      />
      <path
        fill="#004b24"
        d="M339.89,206.16a.87.87,0,0,1,.09-.69c.33-.51,1.22-.5,2.48-.4.79.06,1.69.16,2.59.15a.39.39,0,0,1,.37.31v0a.28.28,0,0,1-.26.34,11.62,11.62,0,0,0-2.43.56c-1.1.35-1.87.64-2.4.35A.93.93,0,0,1,339.89,206.16Z"
      />
      <path
        fill="#004b24"
        d="M315.21,191.19a.81.81,0,0,0-.35.63c0,.61.89,1.06,2,1.73a12.85,12.85,0,0,1,2.19,1.53.4.4,0,0,0,.49,0h0a.29.29,0,0,0,.12-.21.29.29,0,0,0-.08-.22,24.24,24.24,0,0,1-1.56-2c-.71-1-1.26-1.67-2-1.74A1.19,1.19,0,0,0,315.21,191.19Z"
      />
      <path
        fill="#004b24"
        d="M322.05,191.58a.81.81,0,0,0-.43.58c-.07.61.73,1.17,1.77,2a12.82,12.82,0,0,1,1.94,1.81.39.39,0,0,0,.48.09h0a.29.29,0,0,0,.15-.19.29.29,0,0,0,0-.22,24.09,24.09,0,0,1-1.26-2.22c-.56-1.06-1-1.82-1.71-2A1.17,1.17,0,0,0,322.05,191.58Z"
      />
      <path
        fill="#004b24"
        d="M328.72,192.86a.81.81,0,0,0-.5.52c-.15.59.56,1.25,1.48,2.2a12.81,12.81,0,0,1,1.68,2,.39.39,0,0,0,.46.15h0a.29.29,0,0,0,.17-.17.29.29,0,0,0,0-.23,23.82,23.82,0,0,1-1-2.37c-.42-1.12-.76-1.94-1.43-2.19A1.16,1.16,0,0,0,328.72,192.86Z"
      />
      <path
        fill="#004b24"
        d="M335.13,195a.81.81,0,0,0-.56.45c-.23.57.39,1.31,1.17,2.37a12.84,12.84,0,0,1,1.39,2.24.39.39,0,0,0,.44.21h0a.3.3,0,0,0,.19-.15.29.29,0,0,0,0-.23,23.72,23.72,0,0,1-.63-2.47c-.26-1.17-.49-2-1.12-2.36A1.16,1.16,0,0,0,335.13,195Z"
      />
      <path
        fill="#004b24"
        d="M341.21,198a.81.81,0,0,0-.62.37c-.3.53.22,1.35.85,2.5a12.86,12.86,0,0,1,1.09,2.41.39.39,0,0,0,.41.27h0a.29.29,0,0,0,.21-.12.28.28,0,0,0,0-.22,23.9,23.9,0,0,1-.31-2.54c-.11-1.19-.23-2.07-.81-2.48A1.16,1.16,0,0,0,341.21,198Z"
      />
      <path
        fill="#dfe22a"
        d="M312,196.33a.7.7,0,0,1-.77-.62.62.62,0,0,1,.25-.51.62.62,0,0,1-.3-.5.79.79,0,0,1,1.51-.2h0a.69.69,0,0,1,.76.64.73.73,0,0,1-.75.66h0A.75.75,0,0,1,312,196.33Z"
      />
      <path
        fill="#dfe22a"
        d="M321.79,196.58a.69.69,0,0,1-.6-.77.6.6,0,0,0,.16-1,.77.77,0,0,1,1.49.12h0a.69.69,0,0,1,.59.79.72.72,0,0,1-.86.49h0A.74.74,0,0,1,321.79,196.58Z"
      />
      <path
        fill="#dfe22a"
        d="M333.58,199.77a.69.69,0,0,1-.38-.89.61.61,0,0,0,.41-.92.76.76,0,0,1,1.4.5h0a.68.68,0,0,1,.36.91.71.71,0,0,1-1,.25h0A.73.73,0,0,1,333.58,199.77Z"
      />
      <path
        fill="#dfe22a"
        d="M344.08,205.87a.69.69,0,0,1-.14-1,.6.6,0,0,0,.63-.78.77.77,0,0,1,1.23.85h0a.69.69,0,0,1,.12,1,.72.72,0,0,1-1,0h0A.74.74,0,0,1,344.08,205.87Z"
      />
      <path
        fill="#004b24"
        d="M378.07,217.3a.34.34,0,0,1-.27-.4,47.85,47.85,0,0,1,25.14-36.66.31.31,0,1,1,.29.55A47.22,47.22,0,0,0,378.42,217,.34.34,0,0,1,378.07,217.3Z"
      />
      <path
        fill="#004b24"
        d="M400.29,186.68a.87.87,0,0,0-.1-.69c-.35-.5-1.24-.46-2.5-.33-.79.08-1.69.2-2.59.22a.39.39,0,0,0-.37.32v0a.28.28,0,0,0,.27.33,11.8,11.8,0,0,1,2.44.5c1.11.33,1.89.59,2.42.29A.94.94,0,0,0,400.29,186.68Z"
      />
      <path
        fill="#004b24"
        d="M395.77,190.31a.87.87,0,0,0-.2-.67c-.41-.45-1.29-.3-2.51,0-.77.18-1.64.42-2.53.55a.38.38,0,0,0-.32.36s0,0,0,0a.28.28,0,0,0,.31.3A11.73,11.73,0,0,1,393,191c1.14.18,1.95.35,2.42,0A.93.93,0,0,0,395.77,190.31Z"
      />
      <path
        fill="#004b24"
        d="M391.79,194.47a.88.88,0,0,0-.28-.64c-.46-.4-1.31-.13-2.48.32-.74.28-1.57.63-2.43.87a.38.38,0,0,0-.27.4v0a.29.29,0,0,0,.34.25,11.73,11.73,0,0,1,2.48-.14c1.16,0,2,.09,2.39-.34A.92.92,0,0,0,391.79,194.47Z"
      />
      <path
        fill="#004b24"
        d="M388.39,199.12a.87.87,0,0,0-.36-.6c-.51-.33-1.32,0-2.42.64-.69.38-1.47.83-2.3,1.18a.38.38,0,0,0-.21.43v0a.29.29,0,0,0,.37.21,11.69,11.69,0,0,1,2.44-.47c1.15-.12,2-.17,2.33-.65A.92.92,0,0,0,388.39,199.12Z"
      />
      <path
        fill="#004b24"
        d="M385.64,204.17a.88.88,0,0,0-.44-.54c-.55-.26-1.3.22-2.32,1-.64.47-1.35,1-2.13,1.49a.39.39,0,0,0-.16.46v0a.28.28,0,0,0,.4.15,11.68,11.68,0,0,1,2.36-.79c1.13-.28,1.93-.43,2.23-1A.93.93,0,0,0,385.64,204.17Z"
      />
      <path
        fill="#004b24"
        d="M398.83,178.49a.81.81,0,0,1,.63.35c.31.53-.2,1.37-.83,2.54a12.81,12.81,0,0,0-1.06,2.45.4.4,0,0,1-.4.28h0a.29.29,0,0,1-.21-.11.29.29,0,0,1,0-.22,24.19,24.19,0,0,0,.26-2.55c.09-1.2.2-2.08.78-2.52A1.2,1.2,0,0,1,398.83,178.49Z"
      />
      <path
        fill="#004b24"
        d="M393.2,182.41a.81.81,0,0,1,.67.27c.38.48,0,1.38-.47,2.62a12.76,12.76,0,0,0-.71,2.56.39.39,0,0,1-.36.33h0a.29.29,0,0,1-.23-.09.29.29,0,0,1-.08-.21,24.13,24.13,0,0,0-.09-2.56c-.08-1.2-.09-2.08.42-2.59A1.17,1.17,0,0,1,393.2,182.41Z"
      />
      <path
        fill="#004b24"
        d="M388.19,187a.81.81,0,0,1,.7.18c.44.43.17,1.36-.11,2.64a12.78,12.78,0,0,0-.36,2.62.39.39,0,0,1-.31.37h0a.29.29,0,0,1-.24-.06.29.29,0,0,1-.11-.2,23.89,23.89,0,0,0-.43-2.52c-.23-1.18-.37-2,.07-2.61A1.16,1.16,0,0,1,388.19,187Z"
      />
      <path
        fill="#004b24"
        d="M383.85,192.17a.82.82,0,0,1,.71.09c.49.37.35,1.32.24,2.63a12.78,12.78,0,0,0,0,2.64.39.39,0,0,1-.26.41h0a.29.29,0,0,1-.24,0,.29.29,0,0,1-.13-.19,23.92,23.92,0,0,0-.75-2.44c-.39-1.13-.64-2-.28-2.59A1.15,1.15,0,0,1,383.85,192.17Z"
      />
      <path
        fill="#004b24"
        d="M380.22,197.88a.81.81,0,0,1,.72,0c.54.3.52,1.27.58,2.58a12.88,12.88,0,0,0,.33,2.63.39.39,0,0,1-.21.44h0a.29.29,0,0,1-.24,0,.29.29,0,0,1-.15-.17,23.83,23.83,0,0,0-1.07-2.32c-.53-1.08-.89-1.88-.61-2.54A1.16,1.16,0,0,1,380.22,197.88Z"
      />
      <path
        fill="#dfe22a"
        d="M404.29,181.17a.7.7,0,0,0,.33-.93.62.62,0,0,0-.48-.3.62.62,0,0,0,0-.58.79.79,0,0,0-1.4.62h0a.69.69,0,0,0-.31.95.73.73,0,0,0,1,.17h0A.75.75,0,0,0,404.29,181.17Z"
      />
      <path
        fill="#dfe22a"
        d="M396,186.54a.69.69,0,0,0,.11-1,.6.6,0,0,1-.65-.76.77.77,0,0,0-1.21.88h0a.69.69,0,0,0-.09,1,.72.72,0,0,0,1,0h0A.74.74,0,0,0,396,186.54Z"
      />
      <path
        fill="#dfe22a"
        d="M387.66,195.42a.69.69,0,0,0-.15-1,.63.63,0,0,0-.56-.06.62.62,0,0,0-.27-.51.76.76,0,0,0-.93,1.16v0a.68.68,0,0,0,.17,1,.71.71,0,0,0,.95-.29h0A.73.73,0,0,0,387.66,195.42Z"
      />
      <path
        fill="#dfe22a"
        d="M381.92,206.12a.69.69,0,0,0-.39-.89.6.6,0,0,1-.95-.33.77.77,0,0,0-.6,1.37v0a.69.69,0,0,0,.41.9.72.72,0,0,0,.84-.53h0A.74.74,0,0,0,381.92,206.12Z"
      />
      <path
        fill="#004b24"
        d="M357.93,211.33a.34.34,0,0,0,.37-.3,47.84,47.84,0,0,0-13.22-42.44.31.31,0,1,0-.44.44,47.23,47.23,0,0,1,13,41.89A.34.34,0,0,0,357.93,211.33Z"
      />
      <path
        fill="#004b24"
        d="M345.72,175.52a.87.87,0,0,1,.3-.63c.48-.38,1.32-.08,2.49.42.73.31,1.55.69,2.41,1a.39.39,0,0,1,.26.41v0a.28.28,0,0,1-.35.24,11.77,11.77,0,0,0-2.48-.24c-1.16,0-2,0-2.39-.44A.94.94,0,0,1,345.72,175.52Z"
      />
      <path
        fill="#004b24"
        d="M349,180.32a.87.87,0,0,1,.38-.58c.52-.31,1.32.09,2.4.73.68.4,1.44.88,2.25,1.27a.38.38,0,0,1,.2.44v0a.28.28,0,0,1-.38.19,11.77,11.77,0,0,0-2.43-.56c-1.15-.17-2-.24-2.31-.74A.93.93,0,0,1,349,180.32Z"
      />
      <path
        fill="#004b24"
        d="M351.55,185.48A.88.88,0,0,1,352,185c.56-.24,1.29.26,2.28,1,.62.49,1.31,1.06,2.07,1.55a.38.38,0,0,1,.14.46v0a.29.29,0,0,1-.4.14,11.69,11.69,0,0,0-2.33-.87c-1.11-.31-1.91-.5-2.19-1A.92.92,0,0,1,351.55,185.48Z"
      />
      <path
        fill="#004b24"
        d="M353.42,190.91a.87.87,0,0,1,.52-.46c.59-.17,1.25.43,2.12,1.33.55.56,1.16,1.22,1.85,1.81a.38.38,0,0,1,.08.48v0a.29.29,0,0,1-.42.09,11.7,11.7,0,0,0-2.2-1.17c-1.06-.46-1.83-.74-2-1.31A.92.92,0,0,1,353.42,190.91Z"
      />
      <path
        fill="#004b24"
        d="M354.57,196.55a.87.87,0,0,1,.58-.39c.6-.09,1.18.59,1.93,1.6.47.63,1,1.37,1.59,2a.39.39,0,0,1,0,.49v0a.28.28,0,0,1-.43,0,11.68,11.68,0,0,0-2-1.46c-1-.6-1.72-1-1.85-1.57A.92.92,0,0,1,354.57,196.55Z"
      />
      <path
        fill="#004b24"
        d="M349.53,168.13a.81.81,0,0,0-.71.15c-.45.41-.21,1.37,0,2.67a12.85,12.85,0,0,1,.29,2.65.4.4,0,0,0,.3.39h0a.29.29,0,0,0,.24,0,.29.29,0,0,0,.11-.2,24.21,24.21,0,0,1,.5-2.52c.27-1.18.43-2,0-2.64A1.2,1.2,0,0,0,349.53,168.13Z"
      />
      <path
        fill="#004b24"
        d="M353.75,173.53a.81.81,0,0,0-.72.06c-.5.35-.39,1.32-.33,2.64a12.82,12.82,0,0,1-.08,2.65.39.39,0,0,0,.25.42h0a.29.29,0,0,0,.24,0,.29.29,0,0,0,.14-.18,24,24,0,0,1,.84-2.42c.43-1.12.7-2,.36-2.59A1.17,1.17,0,0,0,353.75,173.53Z"
      />
      <path
        fill="#004b24"
        d="M357.19,179.39a.81.81,0,0,0-.72,0c-.55.28-.57,1.25-.68,2.56a12.8,12.8,0,0,1-.43,2.61.39.39,0,0,0,.19.45h0a.29.29,0,0,0,.24,0,.29.29,0,0,0,.16-.16,23.8,23.8,0,0,1,1.15-2.28c.57-1.05,1-1.85.7-2.51A1.16,1.16,0,0,0,357.19,179.39Z"
      />
      <path
        fill="#004b24"
        d="M359.81,185.62a.82.82,0,0,0-.71-.13c-.58.21-.73,1.16-1,2.44a12.79,12.79,0,0,1-.77,2.53.39.39,0,0,0,.13.47h0a.29.29,0,0,0,.24,0,.29.29,0,0,0,.18-.14,23.92,23.92,0,0,1,1.44-2.11c.7-1,1.19-1.7,1-2.4A1.15,1.15,0,0,0,359.81,185.62Z"
      />
      <path
        fill="#004b24"
        d="M361.6,192.14a.81.81,0,0,0-.69-.22c-.6.13-.87,1.06-1.32,2.29a12.87,12.87,0,0,1-1.09,2.41.39.39,0,0,0,.07.48h0a.29.29,0,0,0,.23.08.29.29,0,0,0,.2-.11,23.76,23.76,0,0,1,1.7-1.91c.83-.87,1.4-1.54,1.33-2.25A1.16,1.16,0,0,0,361.6,192.14Z"
      />
      <path
        fill="#dfe22a"
        d="M343.52,169.08a.7.7,0,0,1,0-1,.63.63,0,0,1,.55-.15.62.62,0,0,1,.17-.56.79.79,0,0,1,1.15,1h0a.69.69,0,0,1,0,1,.73.73,0,0,1-1-.13h0A.75.75,0,0,1,343.52,169.08Z"
      />
      <path
        fill="#dfe22a"
        d="M349.82,176.64a.69.69,0,0,1,.18-1,.6.6,0,0,0,.85-.54.77.77,0,0,1,.9,1.2v0a.69.69,0,0,1-.2,1,.72.72,0,0,1-.94-.32h0A.74.74,0,0,1,349.82,176.64Z"
      />
      <path
        fill="#dfe22a"
        d="M355.21,187.59a.69.69,0,0,1,.42-.87.61.61,0,0,0,1-.3.76.76,0,0,1,.55,1.38v0a.68.68,0,0,1-.45.88.71.71,0,0,1-.82-.55h0A.73.73,0,0,1,355.21,187.59Z"
      />
      <path
        fill="#dfe22a"
        d="M357.55,199.51a.69.69,0,0,1,.64-.74.63.63,0,0,1,.5.25.62.62,0,0,1,.5-.29.77.77,0,0,1,.17,1.49v0a.69.69,0,0,1-.66.74.72.72,0,0,1-.65-.75h0A.74.74,0,0,1,357.55,199.51Z"
      />
      <path
        fill="#507431"
        d="M348.42,255.79a.68.68,0,0,1-.87.4h0a.68.68,0,0,1-.4-.87l7.07-19.38a.68.68,0,0,1,.87-.4h0a.68.68,0,0,1,.4.87Z"
      />
      <path
        fill="#507431"
        d="M345.79,252.56a.68.68,0,0,1-.87.4h0a.68.68,0,0,1-.4-.87l7.07-19.38a.68.68,0,0,1,.87-.4h0a.68.68,0,0,1,.4.87Z"
      />
      <path
        fill="#799f4e"
        d="M345.22,252.89a.68.68,0,0,1-.87.4h0a.68.68,0,0,1-.4-.87L351,233a.68.68,0,0,1,.87-.4h0a.68.68,0,0,1,.4.87Z"
      />
      <path
        fill="#799f4e"
        d="M348.94,257.17a.68.68,0,0,1-.87.4h0a.68.68,0,0,1-.4-.87l7.07-19.38a.68.68,0,0,1,.87-.4h0a.68.68,0,0,1,.4.87Z"
      />
      <path
        fill="#799f4e"
        d="M346.61,254.27a.68.68,0,0,1-.87.4h0a.68.68,0,0,1-.4-.87l7.07-19.38a.68.68,0,0,1,.87-.4h0a.68.68,0,0,1,.4.87Z"
      />
      <path
        fill="#92b04c"
        d="M350.63,255.89a.68.68,0,0,1-.87.4h0a.68.68,0,0,1-.4-.87L356.42,236a.68.68,0,0,1,.87-.4h0a.68.68,0,0,1,.4.87Z"
      />
      <path
        fill="#92b04c"
        d="M347.36,254.79a.68.68,0,0,1-.87.4h0a.68.68,0,0,1-.4-.87l7.07-19.39a.68.68,0,0,1,.87-.4h0a.68.68,0,0,1,.4.87Z"
      />
      <path
        fill="#507431"
        d="M353.31,258.07a.68.68,0,0,0,.56.78h0a.68.68,0,0,0,.78-.56L358,237.93a.68.68,0,0,0-.56-.78h0a.68.68,0,0,0-.78.56Z"
      />
      <path
        fill="#507431"
        d="M357.19,256.54a.68.68,0,0,0,.56.78h0a.68.68,0,0,0,.78-.56l3.38-20.35a.68.68,0,0,0-.56-.78h0a.68.68,0,0,0-.78.56Z"
      />
      <path
        fill="#799f4e"
        d="M358.26,257.39a.68.68,0,0,0,.56.78h0a.68.68,0,0,0,.78-.56L363,237.26a.68.68,0,0,0-.56-.78h0a.68.68,0,0,0-.78.56Z"
      />
      <path
        fill="#799f4e"
        d="M352.17,259a.68.68,0,0,0,.56.78h0a.68.68,0,0,0,.78-.56l3.38-20.35a.68.68,0,0,0-.56-.78h0a.68.68,0,0,0-.78.56Z"
      />
      <path
        fill="#799f4e"
        d="M355.63,257.63a.68.68,0,0,0,.56.78h0a.68.68,0,0,0,.78-.56l3.38-20.35a.68.68,0,0,0-.56-.78h0a.68.68,0,0,0-.78.56Z"
      />
      <path
        fill="#92b04c"
        d="M351.34,257.07a.68.68,0,0,0,.56.78h0a.68.68,0,0,0,.78-.56l3.38-20.35a.68.68,0,0,0-.56-.78h0a.68.68,0,0,0-.78.56Z"
      />
      <path
        fill="#92b04c"
        d="M354.72,257.72a.68.68,0,0,0,.56.78h0a.68.68,0,0,0,.78-.56l3.38-20.35a.68.68,0,0,0-.56-.78h0a.68.68,0,0,0-.78.56Z"
      />
      <path
        fill="#507431"
        d="M364.2,259.08a.68.68,0,0,1-.7.65h0a.68.68,0,0,1-.65-.7l.72-20.62a.68.68,0,0,1,.7-.65h0a.68.68,0,0,1,.65.7Z"
      />
      <path
        fill="#507431"
        d="M360.7,256.82a.68.68,0,0,1-.7.65h0a.68.68,0,0,1-.65-.7l.72-20.62a.68.68,0,0,1,.7-.65h0a.68.68,0,0,1,.65.7Z"
      />
      <path
        fill="#799f4e"
        d="M359.48,257.44a.68.68,0,0,1-.7.65h0a.68.68,0,0,1-.65-.7l.72-20.62a.68.68,0,0,1,.7-.65h0a.68.68,0,0,1,.65.7Z"
      />
      <path
        fill="#799f4e"
        d="M365.13,260.23a.68.68,0,0,1-.7.65h0a.68.68,0,0,1-.65-.7l.72-20.62a.68.68,0,0,1,.7-.65h0a.68.68,0,0,1,.65.7Z"
      />
      <path
        fill="#799f4e"
        d="M362,258.2a.68.68,0,0,1-.7.65h0a.68.68,0,0,1-.65-.7l.72-20.62a.68.68,0,0,1,.7-.65h0a.68.68,0,0,1,.65.7Z"
      />
      <path
        fill="#92b04c"
        d="M365.74,258.4a.68.68,0,0,1-.7.65h0a.68.68,0,0,1-.65-.7l.72-20.62a.68.68,0,0,1,.7-.65h0a.68.68,0,0,1,.65.7Z"
      />
      <path
        fill="#92b04c"
        d="M362.88,258.46a.68.68,0,0,1-.7.65h0a.68.68,0,0,1-.65-.7l.72-20.62a.68.68,0,0,1,.7-.65h0a.68.68,0,0,1,.65.7Z"
      />
      <polygon
        fill="#eaeaea"
        points="352.86 223.82 348.29 240.26 366.27 244.02 367.47 220.8 356.23 216.91 352.86 223.82"
      />
      <polygon
        fill="#bfbfbf"
        points="357.42 242.17 366.27 244.02 367.47 220.8 360.98 218.55 357.42 242.17"
      />
      <path
        fill="#67913d"
        d="M378.56,219.84,388.73,235S374.29,233.15,378.56,219.84Z"
      />
      <path
        fill="#456b4e"
        d="M378.55,219.82,388.72,235S392.49,220.89,378.55,219.82Z"
      />
      <path
        fill="#67913d"
        d="M356.66,215.89,361,233.6S348.07,226.91,356.66,215.89Z"
      />
      <path
        fill="#456b4e"
        d="M356.66,215.87,361,233.58S369.38,221.67,356.66,215.87Z"
      />
      <path
        fill="#67913d"
        d="M387.75,211.75l16.4,8S390.74,225.41,387.75,211.75Z"
      />
      <path
        fill="#456b4e"
        d="M387.73,211.74l16.4,8S400.32,205.67,387.73,211.74Z"
      />
      <path
        fill="#67913d"
        d="M375.85,192.85l14.7-10.79S389.33,196.56,375.85,192.85Z"
      />
      <path
        fill="#456b4e"
        d="M375.84,192.86l14.7-10.79S376.33,178.89,375.84,192.86Z"
      />
      <path
        fill="#67913d"
        d="M341.82,214.73,329,227.66S327.93,213.14,341.82,214.73Z"
      />
      <path
        fill="#456b4e"
        d="M341.83,214.72,329,227.65S343.5,228.6,341.83,214.72Z"
      />
      <path
        fill="#67913d"
        d="M337.92,214.75l-18.22-.8S329.75,203.41,337.92,214.75Z"
      />
      <path
        fill="#456b4e"
        d="M337.94,214.75l-18.22-.8S328.8,225.33,337.94,214.75Z"
      />
      <path
        fill="#67913d"
        d="M342.93,196.23l-15.46-9.68S341.41,182.34,342.93,196.23Z"
      />
      <path
        fill="#456b4e"
        d="M342.95,196.24l-15.46-9.68S329.78,200.94,342.95,196.24Z"
      />
      <path
        fill="#e12981"
        d="M397.63,198.64a1.26,1.26,0,0,0,.37-2.48h0l-4.21-.2,4.17-.47h0a1.26,1.26,0,1,0-.54-2.45h0l-5.09,1.69,4.6-2.71,0,0a1.26,1.26,0,0,0-1.49-2h0l-3.92,3.57,3.07-4.3,0,0a1.26,1.26,0,0,0-.9-1.86,1.26,1.26,0,0,0-1.28.62v0l-1.72,3.91,1.07-4.11v0a1.26,1.26,0,1,0-2.48-.37v0l-.23,5-.74-5v0a1.26,1.26,0,0,0-2.43.63v0l1.62,4.39-2.37-4,0,0a1.26,1.26,0,1,0-2,1.49h0l3.1,3.4-3.62-2.8,0,0a1.26,1.26,0,0,0-1.32,2.13h0l4.59,2.23-4.92-1.28h0a1.26,1.26,0,1,0-.37,2.48h0l4.21.19-4.17.47h0a1.26,1.26,0,0,0-1,1.5,1.26,1.26,0,0,0,1,1,1.25,1.25,0,0,0,.46,0h0l5.09-1.68-4.6,2.71,0,0a1.26,1.26,0,1,0,1.49,2h0l4-3.61L383.92,203l0,0a1.26,1.26,0,0,0,2.18,1.24v0l1.72-3.91-1.06,4.12v0a1.26,1.26,0,1,0,2.48.37v0l.24-5,.74,5v0a1.26,1.26,0,0,0,2.43-.63v0L391,199.77l2.37,4,0,0a1.26,1.26,0,1,0,2-1.49h0l-3.09-3.4,3.62,2.8,0,0a1.26,1.26,0,0,0,1.32-2.13h0l-4.6-2.22,4.93,1.27Z"
      />
      <circle
        fill="#fff"
        cx="389.27"
        cy="196.11"
        r="1.8"
        transform="translate(137.34 551.81) rotate(-81.43)"
      />
      <path
        fill="#f4dd2f"
        d="M381,182.93a3.38,3.38,0,1,0-1.26-6.53,3.38,3.38,0,1,0-5.92-3.08,3.38,3.38,0,1,0-6.58,1.12,3.38,3.38,0,1,0-2.48,6.09,3.38,3.38,0,1,0,.57,6.55,3.38,3.38,0,1,0,6,3,3.38,3.38,0,1,0,6.49-1.36,3.38,3.38,0,1,0,3.21-5.8Z"
      />
      <circle
        fill="#fff"
        cx="372.49"
        cy="182.01"
        r="3.09"
        transform="translate(136.99 523.21) rotate(-81.43)"
      />
      <path
        fill="#e12981"
        d="M368.41,186.71l-3-.46,2.48-1.83a2.51,2.51,0,0,0-3-4l-2.48,1.83.46-3a2.51,2.51,0,1,0-5-.75l-.46,3L355.57,179a2.51,2.51,0,1,0-4,3l1.83,2.48-3-.46a2.51,2.51,0,0,0-.75,5l3,.46-2.48,1.83a2.51,2.51,0,0,0,3,4l2.48-1.83-.46,3a2.51,2.51,0,1,0,5,.75l.46-3,1.83,2.48a2.51,2.51,0,0,0,4-3l-1.83-2.48,3,.46a2.51,2.51,0,1,0,.75-5Z"
      />
      <circle
        fill="#fff"
        cx="358.98"
        cy="187.83"
        r="2.76"
        transform="translate(119.75 514.81) rotate(-81.43)"
      />
      <path
        fill="#f34c00"
        d="M384.41,197.42a1.55,1.55,0,0,0,.46-3.06h0l-5.19-.24,5.14-.58h0a1.55,1.55,0,0,0-.66-3h0l-6.27,2.08,5.67-3.35,0,0a1.55,1.55,0,1,0-1.84-2.49h0l-4.83,4.4,3.78-5.3,0,0a1.55,1.55,0,0,0-1.11-2.3,1.56,1.56,0,0,0-1.58.77v0l-2.12,4.82L377.2,184v0a1.55,1.55,0,1,0-3.06-.46v0l-.28,6.21-.92-6.12v0a1.55,1.55,0,0,0-3,.77v0l2,5.42-2.92-5,0,0a1.55,1.55,0,0,0-2.49,1.84v0l3.82,4.19-4.47-3.45,0,0A1.55,1.55,0,1,0,364.2,190h0l5.67,2.75-6.08-1.57h0a1.55,1.55,0,1,0-.46,3.06h0l5.19.24-5.14.58h0a1.55,1.55,0,0,0-1.18,1.84,1.56,1.56,0,0,0,1.28,1.2,1.54,1.54,0,0,0,.56,0h0L370.3,196l-5.68,3.34,0,0a1.55,1.55,0,1,0,1.84,2.49h0l4.88-4.45-3.83,5.35,0,0a1.55,1.55,0,1,0,2.69,1.53v0l2.12-4.82L371,204.58v0a1.55,1.55,0,0,0,3.06.46v0l.29-6.21.91,6.12v0a1.55,1.55,0,1,0,3-.77v0l-2-5.42,2.92,5,0,0a1.55,1.55,0,0,0,2.49-1.84v0l-3.82-4.19,4.47,3.45,0,0A1.55,1.55,0,0,0,384,198.6h0l-5.67-2.74,6.08,1.57Z"
      />
      <circle
        fill="#fff"
        cx="374.09"
        cy="194.3"
        r="2.22"
        transform="translate(126.22 535.26) rotate(-81.43)"
      />
      <path
        fill="#f4dd2f"
        d="M374,201.9a1.54,1.54,0,1,0,.46-3h0l-5.16-.24,5.11-.58h0a1.54,1.54,0,0,0-.1-3,1.52,1.52,0,0,0-.56,0h0l-6.24,2.07,5.65-3.33,0,0a1.54,1.54,0,0,0-1.83-2.48h0l-4.81,4.38,3.77-5.27,0,0a1.54,1.54,0,0,0-.58-2.1,1.54,1.54,0,0,0-2.1.58v0l-2.11,4.8,1.31-5.05v0a1.54,1.54,0,1,0-3-.46v0l-.28,6.18-.91-6.09v0a1.54,1.54,0,1,0-3,.77v0l2,5.39-2.9-4.94,0,0a1.54,1.54,0,1,0-2.48,1.83v0l3.8,4.17-4.45-3.43,0,0a1.54,1.54,0,0,0-1.62,2.62h0l5.64,2.74-6-1.57h0a1.54,1.54,0,1,0-.46,3h0l5.17.24-5.12.58h0a1.54,1.54,0,0,0-1.17,1.84,1.54,1.54,0,0,0,1.84,1.18h0l6.25-2.06-5.65,3.32,0,0a1.54,1.54,0,0,0,1.83,2.48h0L361,201.9l-3.81,5.33,0,0a1.54,1.54,0,0,0,.58,2.1,1.52,1.52,0,0,0,.53.18,1.55,1.55,0,0,0,1.57-.76v0L362,204l-1.3,5.05v0a1.54,1.54,0,1,0,3,.46v0l.29-6.18.91,6.09v0a1.54,1.54,0,0,0,3-.77v0l-2-5.39,2.91,4.93,0,0a1.54,1.54,0,1,0,2.48-1.83v0l-3.8-4.17,4.45,3.44,0,0a1.54,1.54,0,1,0,1.62-2.62h0l-5.64-2.73,6,1.56Z"
      />
      <circle
        fill="#fff"
        cx="363.77"
        cy="198.8"
        r="2.21"
        transform="translate(112.97 528.87) rotate(-81.43)"
      />
      <path
        fill="#faa0a9"
        d="M341.85,192.92c-20-7.72-21.91,5.27-.57,3.77-7.72,19.95,5.27,21.91,3.77.57,20,7.72,21.91-5.27.57-3.77C353.34,173.54,340.35,171.58,341.85,192.92Z"
      />
      <path
        fill="#faa0a9"
        d="M340.78,194.69c-19.57,8.64-11.76,19.22,2.27,3.07,8.64,19.57,19.22,11.76,3.07-2.27,19.57-8.64,11.76-19.22-2.27-3.07C335.21,172.86,324.63,180.66,340.78,194.69Z"
      />
      <circle
        fill="#fff"
        cx="343.45"
        cy="195.09"
        r="2.74"
        transform="translate(99.35 505.63) rotate(-81.43)"
      />
      <path
        fill="#faa0a9"
        d="M398,210.2l-3.61-.54,2.93-2.17a3,3,0,1,0-3.54-4.79l-2.93,2.17.54-3.61a3,3,0,0,0-5.89-.89l-.54,3.61L382.77,201a3,3,0,1,0-4.79,3.54l2.17,2.93-3.61-.54a3,3,0,0,0-.89,5.89l3.61.54-2.93,2.17a3,3,0,1,0,3.54,4.79l2.93-2.17-.54,3.61a3,3,0,0,0,5.89.89l.54-3.61,2.17,2.93a3,3,0,1,0,4.79-3.54l-2.17-2.94,3.61.54a3,3,0,0,0,.89-5.89Z"
      />
      <circle
        fill="#fff"
        cx="386.81"
        cy="211.53"
        r="3.27"
        transform="translate(119.99 562.48) rotate(-81.43)"
      />
      <path
        fill="#f4dd2f"
        d="M341.07,206.05a3.17,3.17,0,1,0-1.18-6.12,3.17,3.17,0,1,0-5.54-2.88,3.17,3.17,0,1,0-6.17,1.05,3.17,3.17,0,1,0-2.32,5.71,3.17,3.17,0,1,0,.54,6.14,3.17,3.17,0,1,0,5.59,2.82,3.17,3.17,0,1,0,6.08-1.28,3.17,3.17,0,1,0,3-5.44Z"
      />
      <circle
        fill="#fff"
        cx="333.13"
        cy="205.19"
        r="2.9"
        transform="translate(80.59 504.01) rotate(-81.43)"
      />
      <path
        fill="#f34c00"
        d="M359,218a1.81,1.81,0,1,0,.54-3.58h0l-6.07-.29,6-.68h.05a1.81,1.81,0,1,0-.78-3.54h0l-7.34,2.43,6.64-3.92,0,0a1.81,1.81,0,0,0-2.15-2.91l0,0-5.65,5.14,4.43-6.2,0,0a1.81,1.81,0,0,0-1.3-2.69,1.82,1.82,0,0,0-1.84.9v0l-2.48,5.64,1.54-5.94v-.05a1.81,1.81,0,1,0-3.58-.54v0l-.33,7.26-1.07-7.16v-.05a1.81,1.81,0,0,0-3.51.9v0l2.34,6.34-3.41-5.8,0,0a1.81,1.81,0,0,0-2.92,2.15l0,0,4.47,4.9-5.23-4,0,0a1.81,1.81,0,0,0-1.91,3.08h0l6.63,3.22-7.1-1.84h-.05a1.81,1.81,0,1,0-.54,3.58h0l6.07.28-6,.68h-.05a1.81,1.81,0,0,0-1.38,2.16,1.81,1.81,0,0,0,2.16,1.38h0l7.34-2.42-6.64,3.91,0,0a1.81,1.81,0,0,0,2.15,2.91l0,0,5.71-5.21-4.48,6.26,0,0a1.81,1.81,0,1,0,3.15,1.79v0l2.47-5.64-1.53,5.94v.05a1.81,1.81,0,0,0,3.58.54v0l.34-7.26,1.07,7.16v.05a1.81,1.81,0,0,0,3.51-.9v0l-2.35-6.33,3.42,5.8,0,0a1.81,1.81,0,0,0,2.91-2.15l0,0-4.46-4.9,5.22,4,0,0a1.81,1.81,0,1,0,1.91-3.08h0l-6.63-3.21L359,218Z"
      />
      <circle
        fill="#fff"
        cx="346.97"
        cy="214.4"
        r="2.6"
        transform="translate(83.25 525.53) rotate(-81.43)"
      />
      <path
        fill="#e12981"
        d="M382.59,221.6a1.81,1.81,0,1,0,.54-3.58h0l-6.07-.29,6-.68h.05a1.81,1.81,0,1,0-.78-3.54h0L375,215.94,381.6,212l0,0a1.81,1.81,0,1,0-2.15-2.91l0,0-5.65,5.14,4.43-6.2,0,0a1.81,1.81,0,0,0-1.3-2.69,1.82,1.82,0,0,0-1.84.9v0l-2.48,5.64,1.54-5.94v-.05a1.81,1.81,0,1,0-3.58-.54v0l-.33,7.26-1.07-7.16v-.05a1.81,1.81,0,0,0-3.51.9v0l2.34,6.34-3.41-5.8,0,0a1.81,1.81,0,0,0-2.91,2.15l0,0,4.47,4.9-5.23-4,0,0a1.81,1.81,0,0,0-1.91,3.08h0l6.63,3.22-7.1-1.84h-.05a1.81,1.81,0,1,0-.54,3.58h0l6.07.28-6,.68h-.05a1.81,1.81,0,0,0-1.38,2.16,1.82,1.82,0,0,0,1.5,1.4,1.8,1.8,0,0,0,.66,0h0l7.34-2.42-6.64,3.91,0,0a1.81,1.81,0,1,0,2.15,2.91l0,0,5.71-5.21-4.48,6.26,0,0a1.81,1.81,0,1,0,3.15,1.79v0l2.47-5.64L366.89,230V230a1.81,1.81,0,1,0,3.58.54v0l.34-7.26,1.07,7.16v.05a1.81,1.81,0,0,0,3.51-.9v0L373,223.22l3.42,5.8,0,0a1.81,1.81,0,1,0,2.91-2.15l0,0-4.46-4.9,5.22,4,0,0a1.81,1.81,0,1,0,1.91-3.08h0l-6.63-3.21,7.11,1.83Z"
      />
      <circle
        fill="#fff"
        cx="370.53"
        cy="217.95"
        r="2.6"
        transform="translate(99.78 551.85) rotate(-81.43)"
      />
      <rect fill="#768391" x="122.31" y="310.25" width="1.01" height="66.01" />
      <line fill="#768391" x1="122.84" y1="376.39" x2="105.75" y2="312.64" />
      <rect
        fill="#768391"
        x="113.79"
        y="311.51"
        width="1.01"
        height="66.01"
        transform="translate(-85.28 41.32) rotate(-15)"
      />
      <line fill="#768391" x1="122.89" y1="376.52" x2="89.88" y2="319.35" />
      <rect
        fill="#768391"
        x="105.88"
        y="314.93"
        width="1.01"
        height="66.01"
        transform="translate(-159.7 99.8) rotate(-30)"
      />
      <line fill="#768391" x1="122.97" y1="376.62" x2="76.29" y2="329.95" />
      <rect
        fill="#768391"
        x="99.12"
        y="320.28"
        width="1.01"
        height="66.01"
        transform="translate(-220.61 173.89) rotate(-44.99)"
      />
      <line fill="#768391" x1="123.07" y1="376.7" x2="65.91" y2="343.7" />
      <rect
        fill="#768391"
        x="93.98"
        y="327.19"
        width="1.01"
        height="66.01"
        transform="translate(-264.7 261.95) rotate(-60)"
      />
      <line fill="#768391" x1="123.19" y1="376.75" x2="59.44" y2="359.67" />
      <rect
        fill="#768391"
        x="90.81"
        y="335.2"
        width="1.01"
        height="66.01"
        transform="translate(-287.98 361.12) rotate(-75)"
      />
      <rect fill="#768391" x="57.32" y="376.26" width="66.01" height="1.01" />
      <line fill="#768391" x1="123.46" y1="376.75" x2="59.7" y2="393.83" />
      <rect
        fill="#768391"
        x="58.57"
        y="384.79"
        width="66.01"
        height="1.01"
        transform="translate(-96.59 36.83) rotate(-15)"
      />
      <line fill="#768391" x1="123.58" y1="376.7" x2="66.41" y2="409.7" />
      <rect
        fill="#768391"
        x="61.99"
        y="392.7"
        width="66.01"
        height="1.01"
        transform="translate(-183.9 100.2) rotate(-30)"
      />
      <line fill="#768391" x1="123.68" y1="376.62" x2="77.01" y2="423.29" />
      <rect
        fill="#768391"
        x="67.34"
        y="399.45"
        width="66.01"
        height="1.01"
        transform="translate(-253.42 188.1) rotate(-45)"
      />
      <line fill="#768391" x1="123.76" y1="376.51" x2="90.76" y2="433.68" />
      <rect
        fill="#768391"
        x="74.26"
        y="404.59"
        width="66.01"
        height="1.01"
        transform="translate(-297.21 295.49) rotate(-60.01)"
      />
      <line fill="#768391" x1="123.81" y1="376.39" x2="106.73" y2="440.15" />
      <rect
        fill="#768391"
        x="82.27"
        y="407.77"
        width="66.01"
        height="1.01"
        transform="translate(-308.92 413.96) rotate(-75)"
      />
      <rect fill="#768391" x="123.33" y="376.26" width="1.01" height="66.01" />
      <line fill="#768391" x1="123.81" y1="376.13" x2="140.9" y2="439.89" />
      <rect
        fill="#768391"
        x="131.85"
        y="375.01"
        width="1.01"
        height="66.01"
        transform="translate(-101.03 48.12) rotate(-14.99)"
      />
      <line fill="#768391" x1="123.76" y1="376.01" x2="156.77" y2="433.17" />
      <rect
        fill="#768391"
        x="139.76"
        y="371.59"
        width="1.01"
        height="66.01"
        transform="translate(-183.47 124.3) rotate(-29.99)"
      />
      <line fill="#768391" x1="123.68" y1="375.9" x2="170.36" y2="422.58" />
      <rect
        fill="#768391"
        x="146.51"
        y="366.24"
        width="1.01"
        height="66.01"
        transform="translate(-239.26 220.93) rotate(-45.01)"
      />
      <line fill="#768391" x1="123.58" y1="375.82" x2="180.74" y2="408.83" />
      <rect
        fill="#768391"
        x="151.65"
        y="359.32"
        width="1.01"
        height="66.01"
        transform="translate(-263.68 327.9) rotate(-60)"
      />
      <line fill="#768391" x1="123.46" y1="375.77" x2="187.21" y2="392.86" />
      <rect
        fill="#768391"
        x="154.83"
        y="351.31"
        width="1.01"
        height="66.01"
        transform="translate(-256.09 434.9) rotate(-75)"
      />
      <rect fill="#768391" x="123.33" y="375.25" width="66.01" height="1.01" />
      <line fill="#768391" x1="123.19" y1="375.77" x2="186.95" y2="358.69" />
      <rect
        fill="#768391"
        x="122.07"
        y="366.73"
        width="66.01"
        height="1.01"
        transform="translate(-89.76 52.64) rotate(-15)"
      />
      <line fill="#768391" x1="123.07" y1="375.82" x2="180.24" y2="342.82" />
      <rect
        fill="#768391"
        x="118.65"
        y="358.82"
        width="66.01"
        height="1.01"
        transform="translate(-159.33 123.95) rotate(-30)"
      />
      <line fill="#768391" x1="122.97" y1="375.9" x2="169.64" y2="329.23" />
      <rect
        fill="#768391"
        x="113.3"
        y="352.06"
        width="66.01"
        height="1.01"
        transform="translate(-206.45 206.72) rotate(-45)"
      />
      <line fill="#768391" x1="122.89" y1="376.01" x2="155.89" y2="318.85" />
      <rect
        fill="#768391"
        x="106.39"
        y="346.92"
        width="66.01"
        height="1.01"
        transform="translate(-231.19 294.44) rotate(-60)"
      />
      <line fill="#768391" x1="122.84" y1="376.13" x2="139.92" y2="312.37" />
      <rect
        fill="#768391"
        x="98.37"
        y="343.75"
        width="66.01"
        height="1.01"
        transform="translate(-235.14 382.1) rotate(-75.01)"
      />
      <rect fill="#768391" x="374.31" y="305.9" width="1.01" height="66.01" />
      <line fill="#768391" x1="374.84" y1="372.04" x2="357.75" y2="308.28" />
      <rect
        fill="#768391"
        x="365.79"
        y="307.16"
        width="1.01"
        height="66.01"
        transform="translate(-75.59 106.45) rotate(-15.01)"
      />
      <line fill="#768391" x1="374.89" y1="372.16" x2="341.88" y2="315" />
      <rect
        fill="#768391"
        x="357.88"
        y="310.58"
        width="1.01"
        height="66.01"
        transform="translate(-123.79 225.29) rotate(-30.01)"
      />
      <line fill="#768391" x1="374.97" y1="372.27" x2="328.29" y2="325.59" />
      <rect
        fill="#768391"
        x="351.12"
        y="315.93"
        width="1.01"
        height="66.01"
        transform="translate(-143.74 350.89) rotate(-45.01)"
      />
      <line fill="#768391" x1="375.07" y1="372.35" x2="317.91" y2="339.34" />
      <rect
        fill="#768391"
        x="345.98"
        y="322.84"
        width="1.01"
        height="66.01"
        transform="translate(-134.91 478.05) rotate(-60.01)"
      />
      <line fill="#768391" x1="375.19" y1="372.4" x2="311.44" y2="355.31" />
      <rect
        fill="#768391"
        x="342.81"
        y="330.85"
        width="1.01"
        height="66.01"
        transform="translate(-96.99 601.31) rotate(-75)"
      />
      <rect fill="#768391" x="309.32" y="371.91" width="66.01" height="1.01" />
      <line fill="#768391" x1="375.46" y1="372.4" x2="311.7" y2="389.48" />
      <rect
        fill="#768391"
        x="310.57"
        y="380.43"
        width="66.01"
        height="1.01"
        transform="translate(-86.92 101.95) rotate(-15.01)"
      />
      <line fill="#768391" x1="375.58" y1="372.35" x2="318.41" y2="405.35" />
      <rect
        fill="#768391"
        x="313.99"
        y="388.34"
        width="66.01"
        height="1.01"
        transform="matrix(0.87, -0.5, 0.5, 0.87, -147.94, 225.6)"
      />
      <line fill="#768391" x1="375.68" y1="372.27" x2="329.01" y2="418.94" />
      <rect
        fill="#768391"
        x="319.34"
        y="395.1"
        width="66.01"
        height="1.01"
        transform="translate(-176.53 365.02) rotate(-45)"
      />
      <line fill="#768391" x1="375.76" y1="372.16" x2="342.76" y2="429.32" />
      <rect
        fill="#768391"
        x="326.26"
        y="400.24"
        width="66.01"
        height="1.01"
        transform="translate(-167.45 511.39) rotate(-59.99)"
      />
      <line fill="#768391" x1="375.81" y1="372.04" x2="358.73" y2="435.8" />
      <rect
        fill="#768391"
        x="334.27"
        y="403.41"
        width="66.01"
        height="1.01"
        transform="translate(-117.97 654.08) rotate(-74.99)"
      />
      <rect fill="#768391" x="375.33" y="371.91" width="1.01" height="66.01" />
      <line fill="#768391" x1="375.81" y1="371.78" x2="392.9" y2="435.54" />
      <rect
        fill="#768391"
        x="383.85"
        y="370.65"
        width="1.01"
        height="66.01"
        transform="translate(-91.33 113.16) rotate(-14.99)"
      />
      <line fill="#768391" x1="375.76" y1="371.66" x2="408.77" y2="428.82" />
      <rect
        fill="#768391"
        x="391.76"
        y="367.23"
        width="1.01"
        height="66.01"
        transform="translate(-147.56 249.73) rotate(-30)"
      />
      <line fill="#768391" x1="375.68" y1="371.55" x2="422.36" y2="418.22" />
      <rect
        fill="#768391"
        x="398.51"
        y="361.88"
        width="1.01"
        height="66.01"
        transform="translate(-162.36 397.75) rotate(-44.99)"
      />
      <line fill="#768391" x1="375.58" y1="371.47" x2="432.74" y2="404.47" />
      <rect
        fill="#768391"
        x="403.65"
        y="354.97"
        width="1.01"
        height="66.01"
        transform="translate(-133.93 543.96) rotate(-60)"
      />
      <line fill="#768391" x1="375.46" y1="371.42" x2="439.21" y2="388.5" />
      <rect
        fill="#768391"
        x="406.83"
        y="346.96"
        width="1.01"
        height="66.01"
        transform="translate(-65.16 674.99) rotate(-74.99)"
      />
      <rect fill="#768391" x="375.33" y="370.9" width="66.01" height="1.01" />
      <line fill="#768391" x1="375.19" y1="371.42" x2="438.95" y2="354.34" />
      <rect
        fill="#768391"
        x="374.07"
        y="362.37"
        width="66.01"
        height="1.01"
        transform="translate(-80.06 117.75) rotate(-15)"
      />
      <line fill="#768391" x1="375.07" y1="371.47" x2="432.24" y2="338.47" />
      <rect
        fill="#768391"
        x="370.65"
        y="354.46"
        width="66.01"
        height="1.01"
        transform="translate(-123.4 249.36) rotate(-30)"
      />
      <line fill="#768391" x1="374.97" y1="371.55" x2="421.64" y2="324.88" />
      <rect
        fill="#768391"
        x="365.3"
        y="347.71"
        width="66.01"
        height="1.01"
        transform="translate(-129.56 383.63) rotate(-45)"
      />
      <line fill="#768391" x1="374.89" y1="371.66" x2="407.89" y2="314.49" />
      <rect
        fill="#768391"
        x="358.39"
        y="342.57"
        width="66.01"
        height="1.01"
        transform="translate(-101.4 510.55) rotate(-60.01)"
      />
      <line fill="#768391" x1="374.84" y1="371.78" x2="391.92" y2="308.02" />
      <rect
        fill="#768391"
        x="350.38"
        y="339.39"
        width="66.01"
        height="1.01"
        transform="translate(-44.12 622.31) rotate(-75.01)"
      />
      <path
        fill="#8796a6"
        d="M122.47,443.58a67.32,67.32,0,1,1,67.32-67.32A67.39,67.39,0,0,1,122.47,443.58Zm0-126.54a59.22,59.22,0,1,0,59.22,59.22A59.29,59.29,0,0,0,122.47,317Z"
      />
      <path
        fill="#8796a6"
        d="M375.33,442.92a69.85,69.85,0,1,1,69.85-69.85A69.93,69.93,0,0,1,375.33,442.92Zm0-131.62a61.76,61.76,0,1,0,61.76,61.76A61.83,61.83,0,0,0,375.33,311.3Z"
      />
      <path
        fill="#001f30"
        d="M375.33,453.73A80.67,80.67,0,1,1,456,373.06,80.77,80.77,0,0,1,375.33,453.73Zm0-150.28a69.61,69.61,0,1,0,69.61,69.61A69.69,69.69,0,0,0,375.33,303.45Z"
      />
      <path
        fill="#001f30"
        d="M122.47,454.73a78.47,78.47,0,1,1,78.47-78.47A78.56,78.56,0,0,1,122.47,454.73Zm0-145.88a67.41,67.41,0,1,0,67.41,67.41A67.48,67.48,0,0,0,122.47,308.86Z"
      />
      <path
        fill="#8796a6"
        d="M227.7,405.45a21.9,21.9,0,1,1,21.9-21.9A21.92,21.92,0,0,1,227.7,405.45Zm0-40.11a18.21,18.21,0,1,0,18.21,18.21A18.23,18.23,0,0,0,227.7,365.35Z"
      />
      <path
        fill="#8796a6"
        d="M227.7,398.26a14.09,14.09,0,1,1,14.09-14.09A14.11,14.11,0,0,1,227.7,398.26Zm0-25.82a11.72,11.72,0,1,0,11.72,11.72A11.74,11.74,0,0,0,227.7,372.44Z"
      />
      <circle fill="#8796a6" cx="227.7" cy="384.16" r="5.88" />
      <path fill="#ec6d05" d="M340.7,231.57l-.76.08.58.36Z" />
      <path
        fill="#ec7f26"
        d="M340.52,232l-.58-.36-6.18.68.65,5.9c1.06.7,2.12,1.36,3.18,2C338.57,237.52,339.42,234.71,340.52,232Z"
      />
      <path
        fill="#ec6d05"
        d="M337.59,240.27c-1.06-.68-2.12-1.35-3.18-2l.85,7.65A45.25,45.25,0,0,0,337.59,240.27Z"
      />
      <path
        fill="#ec6d05"
        d="M346.71,230.9l-6,.66-.18.45q2.31,1.41,4.65,2.8C345.67,233.51,346.18,232.2,346.71,230.9Z"
      />
      <path
        fill="#ec6d05"
        d="M345.17,234.82q-2.34-1.39-4.65-2.8c-1.1,2.69-2,5.51-2.93,8.26,1.41.91,2.83,1.82,4.25,2.71C343,240.31,344.1,237.56,345.17,234.82Z"
      />
      <path
        fill="#ec6d05"
        d="M341.84,243c-1.42-.89-2.83-1.8-4.25-2.71a45.25,45.25,0,0,1-2.34,5.61l.34,3.08,2.27,1.49A55.43,55.43,0,0,0,341.84,243Z"
      />
      <path
        fill="#ec6d05"
        d="M337.87,250.45,335.6,249l.45,4.09C336.7,252.21,337.3,251.34,337.87,250.45Z"
      />
      <path
        fill="#ec6d05"
        d="M361.19,229.3l-5.29.58-.63,1.44c1.32.91,2.65,1.82,4,2.7C359.94,232.46,360.59,230.89,361.19,229.3Z"
      />
      <path
        fill="#ec6d05"
        d="M359.27,234c-1.35-.88-2.68-1.78-4-2.7-1.1,2.5-2.18,5-3.27,7.52l3.93,2.3C357.1,238.79,358.23,236.43,359.27,234Z"
      />
      <path
        fill="#ec6d05"
        d="M355.93,241.13l-3.93-2.3c-1.2,2.73-2.43,5.44-3.73,8.09,1.17.71,2.35,1.4,3.52,2.09C353.21,246.42,354.6,243.79,355.93,241.13Z"
      />
      <path
        fill="#ec6d05"
        d="M347.57,256.54c-1.14-.7-2.28-1.39-3.42-2.1a63.1,63.1,0,0,1-4.2,6l3.92,2.58Q345.71,259.8,347.57,256.54Z"
      />
      <path
        fill="#ec6d05"
        d="M343.87,263.07,340,260.49c-.87,1.1-1.76,2.18-2.73,3.22l.61,5.55q1,.62,2,1.26Q341.79,266.77,343.87,263.07Z"
      />
      <path
        fill="#ec6d05"
        d="M339.8,270.51q-1-.64-2-1.26l.45,4.1C338.78,272.4,339.29,271.46,339.8,270.51Z"
      />
      <path
        fill="#ec6d05"
        d="M351.78,249c-1.17-.7-2.35-1.39-3.52-2.09-1.27,2.57-2.62,5.08-4.11,7.51,1.14.71,2.28,1.4,3.42,2.1C349,254,350.41,251.54,351.78,249Z"
      />
      <path fill="#ec6d05" d="M366.39,228.73l-.54.06.46.28Z" />
      <path
        fill="#ec6d05"
        d="M366.31,229.07l-.46-.28-4.66.51c-.6,1.59-1.25,3.16-1.92,4.72q2.48,1.61,5,3.14Q365.37,233.15,366.31,229.07Z"
      />
      <path
        fill="#ec7f26"
        d="M364.28,237.16q-2.53-1.53-5-3.14c-1,2.41-2.17,4.77-3.34,7.12l6.09,3.57C362.82,242.21,363.59,239.7,364.28,237.16Z"
      />
      <path
        fill="#ec6d05"
        d="M362,244.71l-6.09-3.57c-1.32,2.66-2.72,5.28-4.14,7.89q3.59,2.13,7.21,4.22Q360.62,249,362,244.71Z"
      />
      <path
        fill="#ec7f26"
        d="M359,253.25q-3.62-2.09-7.21-4.22c-1.38,2.52-2.79,5-4.21,7.51q4,2.43,8,4.76Q357.4,257.33,359,253.25Z"
      />
      <path
        fill="#ec6d05"
        d="M355.56,261.29q-4-2.33-8-4.76-1.85,3.26-3.7,6.53,4.05,2.65,8.14,5.24C353.26,266,354.46,263.67,355.56,261.29Z"
      />
      <path
        fill="#ec7f26"
        d="M352,268.31q-4.09-2.59-8.14-5.24-2.08,3.7-4.07,7.44c2.62,1.72,5.19,3.53,7.75,5.37Q349.93,272.17,352,268.31Z"
      />
      <path
        fill="#ec6d05"
        d="M339.8,270.51c-.5.95-1,1.89-1.51,2.84l.16,1.44a4.73,4.73,0,0,0,5.22,4.18l2-.22c.65-.95,1.29-1.9,1.9-2.87C345,274,342.41,272.23,339.8,270.51Z"
      />
      <path
        fill="#ec6d05"
        d="M371.71,228.14l-5.33.59-.08.34q2.27,1.39,4.51,2.82Q371.25,230,371.71,228.14Z"
      />
      <path
        fill="#ec6d05"
        d="M370.82,231.89q-2.24-1.43-4.51-2.82-.93,4.09-2,8.09,2.33,1.41,4.7,2.76C369.6,237.23,370.19,234.55,370.82,231.89Z"
      />
      <path
        fill="#ec6d05"
        d="M367.12,247.75q-2.55-1.54-5.1-3-1.39,4.33-3,8.54l5.63,3.24C365.55,253.6,366.38,250.69,367.12,247.75Z"
      />
      <path
        fill="#ec6d05"
        d="M364.62,256.49,359,253.25q-1.59,4.09-3.43,8,3,1.73,6,3.4C362.72,262,363.73,259.26,364.62,256.49Z"
      />
      <path
        fill="#ec6d05"
        d="M361.55,264.69q-3-1.67-6-3.4c-1.1,2.38-2.3,4.71-3.55,7q2.91,1.84,5.85,3.65A68.47,68.47,0,0,0,361.55,264.69Z"
      />
      <path
        fill="#ec6d05"
        d="M354,277.83a59.5,59.5,0,0,0,3.89-5.88q-2.94-1.8-5.85-3.65-2.09,3.86-4.46,7.58l3.2,2.3Z"
      />
      <path
        fill="#ec6d05"
        d="M350.75,278.19l-3.2-2.3c-.62,1-1.25,1.92-1.9,2.87Z"
      />
      <path
        fill="#ec6d05"
        d="M369,239.91q-2.37-1.34-4.7-2.76c-.69,2.55-1.46,5.06-2.26,7.55q2.55,1.51,5.1,3C367.78,245.15,368.39,242.53,369,239.91Z"
      />
      <path
        fill="#ec6d05"
        d="M377.68,227.48l-6,.66q-.46,1.87-.9,3.75c1.67,1.06,3.34,2.11,5,3.15Z"
      />
      <path
        fill="#ec7f26"
        d="M375.84,235c-1.69-1-3.36-2.09-5-3.15-.62,2.66-1.22,5.34-1.83,8q2.48,1.41,5,2.74Z"
      />
      <path
        fill="#ec6d05"
        d="M374,242.65q-2.52-1.33-5-2.74c-.6,2.62-1.21,5.23-1.87,7.84q2.45,1.49,4.89,3Z"
      />
      <path
        fill="#ec7f26"
        d="M372,250.77q-2.44-1.53-4.89-3c-.74,2.94-1.57,5.85-2.5,8.74l5.27,3Z"
      />
      <path
        fill="#ec6d05"
        d="M369.89,259.49l-5.27-3c-.9,2.77-1.91,5.51-3.07,8.2q3.11,1.73,6.26,3.4Z"
      />
      <path
        fill="#ec7f26"
        d="M366.53,273.31l1.27-5.23q-3.15-1.67-6.26-3.4a68.47,68.47,0,0,1-3.69,7.27q3.39,2.08,6.83,4.07A4.71,4.71,0,0,0,366.53,273.31Z"
      />
      <path
        fill="#ec6d05"
        d="M364.69,276q-3.43-2-6.83-4.07a59.5,59.5,0,0,1-3.89,5.88l8.49-.94A4.71,4.71,0,0,0,364.69,276Z"
      />
      <path
        fill="#ec6d05"
        d="M355.27,231.32l-1.67-1.18-6.89.76c-.53,1.3-1,2.61-1.54,3.91q3.39,2,6.83,4C353.09,236.34,354.16,233.83,355.27,231.32Z"
      />
      <path
        fill="#ec7f26"
        d="M352,238.84q-3.44-2-6.83-4c-1.06,2.75-2.13,5.49-3.33,8.17q3.2,2,6.42,3.95C349.57,244.27,350.8,241.57,352,238.84Z"
      />
      <path
        fill="#ec6d05"
        d="M348.26,246.93Q345,245,341.84,243a55.43,55.43,0,0,1-4,7.47q3.14,2,6.28,4C345.64,252,347,249.5,348.26,246.93Z"
      />
      <path
        fill="#ec7f26"
        d="M344.15,254.44q-3.15-2-6.28-4c-.57.89-1.17,1.76-1.82,2.6l.58,5.23,3.33,2.2A63.1,63.1,0,0,0,344.15,254.44Z"
      />
      <path
        fill="#ec6d05"
        d="M340,260.49l-3.33-2.2.6,5.43C338.19,262.67,339.09,261.59,340,260.49Z"
      />
      <path fill="#ec6d05" d="M355.9,229.89l-2.31.25,1.67,1.18Z" />
      <path
        fill="#85001e"
        d="M178.71,302.45a1.84,1.84,0,0,1-1.3-.54l-14-14a14.08,14.08,0,0,0-10-4.13H65.39a1.84,1.84,0,0,1,0-3.69H155a14.09,14.09,0,0,1,10,4.13L180,299.3a1.84,1.84,0,0,1-1.3,3.15Z"
      />
      <path
        fill="#ff003c"
        d="M130.58,370.9l-5-2.37c1.38-2.91,34.21-71.39,65.19-74.9l.62,5.5C163.44,302.3,130.9,370.22,130.58,370.9Z"
      />
      <rect
        fill="#ff003c"
        x="200.46"
        y="282.18"
        width="5.53"
        height="78.91"
        transform="translate(-110.14 105.88) rotate(-23.17)"
      />
      <rect
        fill="#ff003c"
        x="179.41"
        y="261.68"
        width="5.53"
        height="23.17"
        transform="translate(-91.15 91.18) rotate(-22.64)"
      />
      <path
        fill="#85001e"
        d="M157.92,248c12.38,1.11,35.69-1.13,49.59-4.74,3.16-.82,4.87,3.83,2.28,5.81l-24.06,18.46c-15.22,1.07-25.29-3-31.54-10.81C151.53,253.38,153.66,247.61,157.92,248Z"
      />
      <path
        fill="#ff003c"
        d="M300.71,287.93l-2-19.43c-10.29,4.28-22.28,12.65-32.8,28.86a130.93,130.93,0,0,1-7.95,11c13.61-1,19.9,3.33,22.34,5.81A86.71,86.71,0,0,1,300.71,287.93Z"
      />
      <path
        fill="#ff003c"
        d="M258.21,292.34c-24.1,37.14-48.76,36.17-49.8,36.11l-.64,9.2c.11,0,.47,0,1,0,5,0,26.91-1.73,49.18-29.34a130.93,130.93,0,0,0,7.95-11c10.51-16.2,22.51-24.58,32.8-28.86,13.27-5.52,23.7-4.23,24.44-4.13l1.34-9.12C322.94,255,285.63,250.08,258.21,292.34Z"
      />
      <path
        fill="#ff003c"
        d="M326,271.8a92.56,92.56,0,0,0-25.32,16.13,86.71,86.71,0,0,0-20.37,26.22,81.45,81.45,0,0,0-5.48,14.17c-10,35.26-29.67,38.9-30.5,39l.7,4.56-.65-4.56,1.3,9.13c1.07-.15,26.28-4.2,38-45.64C294,294.58,329,280.53,329.35,280.4Z"
      />
      <path
        fill="#ff003c"
        d="M367.28,366.35c-1.73-3.46-43.06-86.66-49.06-114.84l5.41-1.15c5.85,27.5,47.49,111.07,47.91,111.91Z"
      />
      <polygon
        fill="#ff003c"
        points="334.34 231.51 324.97 231.51 328.86 227.57 334.34 227.82 334.34 231.51"
      />
      <polygon
        fill="#ff003c"
        points="324.98 231.51 307.17 214 292.05 211.52 292.95 206.06 309.8 208.82 328.86 227.57 324.98 231.51"
      />
      <rect fill="#ff003c" x="318.28" y="223.55" width="5.19" height="28.62" />
      <rect
        fill="#b55405"
        x="332.28"
        y="222.6"
        width="47.15"
        height="9.78"
        rx="1.18"
        ry="1.18"
        transform="translate(-22.84 40.47) rotate(-6.31)"
      />
      <polygon
        fill="#bac3c9"
        points="113.09 374.2 53.83 329.82 56.04 326.87 115.31 371.25 113.09 374.2"
      />
      <polygon
        fill="#bac3c9"
        points="117.88 367.89 58.48 325.54 60.62 322.54 123.33 367.25 117.88 367.89"
      />
      <rect
        fill="#bac3c9"
        x="325.84"
        y="330.69"
        width="3.69"
        height="78.05"
        transform="translate(-68.74 664.53) rotate(-85.09)"
      />
      <polygon
        fill="#bac3c9"
        points="365.36 368.21 289.6 359.73 290.01 356.07 371.55 365.19 365.36 368.21"
      />
      <path
        fill="#ff003c"
        d="M201.4,348a2.77,2.77,0,0,1-2.57-1.75,82.06,82.06,0,0,0-148.49-9.06,2.77,2.77,0,1,1-4.86-2.64A87.6,87.6,0,0,1,204,344.2,2.77,2.77,0,0,1,201.4,348Z"
      />
      <path
        fill="#ff003c"
        d="M289,379.33a2.77,2.77,0,0,1-2.76-2.6A87.6,87.6,0,0,1,425.76,301a2.77,2.77,0,0,1-3.29,4.45,82.06,82.06,0,0,0-130.74,71,2.77,2.77,0,0,1-2.59,2.93Z"
      />
      <circle fill="#001f30" cx="375.33" cy="371.91" r="10.49" />
      <rect
        fill="#85001e"
        x="179.7"
        y="280.51"
        width="13.85"
        height="6.9"
        rx="1.67"
        ry="1.67"
        transform="translate(-89.08 85.35) rotate(-20.92)"
      />
      <path
        fill="#85001e"
        d="M86.92,286v-4.29a1.21,1.21,0,0,0-1.21-1.21h-2a1.21,1.21,0,0,0-1.21,1.21V286a1.21,1.21,0,0,1-1.21,1.21H80.05a1.21,1.21,0,0,0-1.21,1.21v2a1.21,1.21,0,0,0,1.21,1.21h9.48a1.21,1.21,0,0,0,1.21-1.21v-2a1.21,1.21,0,0,0-1.21-1.21h-1.4A1.21,1.21,0,0,1,86.92,286Zm-.59-4.91v1.25l-3.23.47V281.1Z"
      />
      <path
        fill="#ff003c"
        d="M251.14,385.45l-.56-2.32c-.24-1-.4-2.15-.57-3.36-1.42-10.29-3.37-24.39-57-23.71-58,.74-65.84,10.53-66.54,11.64l-.71,1.13,4,4.89v9.33ZM150.22,368.86c.43-.69,5.25-6.74,41.08-7.19,33.09-.42,34.29,8.28,35.17,14.64.1.75.2,1.45.35,2.07l.35,1.43-74.89-1.48v-5.76l-2.5-3Z"
      />
      <circle fill="#001f30" cx="122.47" cy="376.26" r="10.49" />
      <path
        fill="#85001e"
        d="M256.94,380.72H227.22a5.83,5.83,0,0,1-5.83-5.83v-.63a5.83,5.83,0,0,1,5.52-5.82l22.7-1.21,6.81-.62a5.83,5.83,0,0,1,6.36,5.8v2.48A5.83,5.83,0,0,1,256.94,380.72Z"
      />
      <circle fill="#85001e" cx="289.12" cy="210.89" r="7.22" />
      <path
        fill="#9e9e9e"
        d="M166.44,221s22.34,37.54,54.52,58.09,18.77,59,20.56,68.82S263,372,263,372s10.72,0,0-13.41-9.83-23.24-1.79-29.49S279,257.61,279,243.32c0-13.29,0-47.37-48.26-31.28S166.44,221,166.44,221Z"
      />
      <path
        fill="#9e9e9e"
        d="M260.72,137.41s44.69,61.67,44.69,73.28a14.55,14.55,0,0,1-.82,5c-1.91,5.21-9.47,4.44-10.59-1a30.05,30.05,0,0,0-4.68-11.2c-6.26-8.94-63.45-32.17-63.45-32.17S225,131.15,260.72,137.41Z"
      />
      <path
        fill="#464477"
        d="M260.72,137.41q7,9.53,13.81,19.22t13.27,19.61c4.31,6.61,8.5,13.31,12.32,20.23a71.47,71.47,0,0,1,5,10.78,12,12,0,0,1,.42,1.46c.06.25.13.49.17.75l.08.79a13,13,0,0,1-.14,3.05,11.54,11.54,0,0,1-.82,3,5.48,5.48,0,0,1-2,2.47,5.93,5.93,0,0,1-3,1,6.39,6.39,0,0,1-3.1-.67,5.8,5.8,0,0,1-2.37-2.12,8.53,8.53,0,0,1-1-2.92,28.12,28.12,0,0,0-4.68-10.55,22.55,22.55,0,0,0-4.41-3.7c-1.62-1.1-3.29-2.12-5-3.11-3.4-2-6.88-3.81-10.39-5.61-7-3.57-14.17-6.91-21.35-10.16s-14.39-6.45-21.66-9.52c14.65,5.85,29.19,12,43.36,19,3.54,1.75,7.05,3.56,10.48,5.55,1.71,1,3.41,2,5.06,3.13a23.2,23.2,0,0,1,4.62,3.87,29,29,0,0,1,4.9,10.9,7.56,7.56,0,0,0,.91,2.62,4.83,4.83,0,0,0,2,1.76,5.41,5.41,0,0,0,2.62.56,5,5,0,0,0,2.51-.84c1.53-1,2.11-2.9,2.43-4.76a12.06,12.06,0,0,0,.14-2.84l-.07-.68c0-.23-.11-.46-.16-.69a11.17,11.17,0,0,0-.39-1.38,70.74,70.74,0,0,0-4.94-10.64c-3.76-6.91-8-13.59-12.17-20.25s-8.6-13.22-13-19.75S265.27,143.86,260.72,137.41Z"
      />
      <path
        fill="#9e9e9e"
        d="M340.32,73.88c-9-16.12-31.38-23.85-49.7-21.44-19.7,2.6-39.09,11-44.39,32.19s0,38.43,49,59.63c34.45-9.28,49-35.78,49-46.38C344.29,88.61,343.13,78.88,340.32,73.88Z"
      />
      <path
        fill="#f49595"
        d="M279.2,56.9s-1.89,2.58-4.66,5.7c-4.4,5-11,11.45-15.53,11-7.35-.76-6.26-27.32-2.65-28.28S279.2,56.9,279.2,56.9Z"
      />
      <path
        fill="url(#linear-gradient)"
        d="M279.2,56.9s-1.89,2.58-4.66,5.7c-3.66-5.64-11.18-14.14-18.18-17.29C260,44.36,279.2,56.9,279.2,56.9Z"
      />
      <path
        fill="#f49595"
        d="M318.8,60.48s1.89,2.58,4.66,5.7c4.4,5,11,11.45,15.53,11,7.35-.76,6.26-27.32,2.65-28.28S318.8,60.48,318.8,60.48Z"
      />
      <path
        fill="url(#linear-gradient-2)"
        d="M318.8,60.48s1.89,2.58,4.66,5.7c3.66-5.64,11.18-14.14,18.18-17.29C338,47.94,318.8,60.48,318.8,60.48Z"
      />
      <path
        fill="#070707"
        d="M303.23,110.8l-3.57-3.33a.34.34,0,0,1,.23-.58H307a.34.34,0,0,1,.23.58l-3.57,3.33A.34.34,0,0,1,303.23,110.8Z"
      />
      <path
        fill="#070707"
        d="M292.84,107.68a39.26,39.26,0,0,0-4.74.25,30.57,30.57,0,0,0-4.6.89,22.63,22.63,0,0,0-4.36,1.65l-1,.54-1,.62c-.71.37-1.27.92-2,1.36a14.09,14.09,0,0,1,3.62-3.2,16.61,16.61,0,0,1,4.47-1.94A19.12,19.12,0,0,1,292.84,107.68Z"
      />
      <path
        fill="#070707"
        d="M292.84,111.61c-1.34.29-2.64.66-3.93,1.05a33.47,33.47,0,0,0-3.74,1.42,22.42,22.42,0,0,0-3.46,2,20.59,20.59,0,0,0-3,2.66,10.89,10.89,0,0,1,2.55-3.27,14.91,14.91,0,0,1,3.53-2.25A17.58,17.58,0,0,1,292.84,111.61Z"
      />
      <path
        fill="#070707"
        d="M314.08,107.68a19.12,19.12,0,0,1,9.6.18,16.61,16.61,0,0,1,4.47,1.94,14.09,14.09,0,0,1,3.62,3.2c-.69-.43-1.25-1-2-1.36l-1-.62-1-.54a22.63,22.63,0,0,0-4.36-1.65,30.57,30.57,0,0,0-4.6-.89A39.26,39.26,0,0,0,314.08,107.68Z"
      />
      <path
        fill="#070707"
        d="M314.08,111.61a17.58,17.58,0,0,1,8.09,1.56,14.9,14.9,0,0,1,3.53,2.25,10.89,10.89,0,0,1,2.55,3.27,20.59,20.59,0,0,0-3-2.66,22.42,22.42,0,0,0-3.46-2,33.46,33.46,0,0,0-3.74-1.42C316.72,112.28,315.42,111.91,314.08,111.61Z"
      />
      <circle fill="#f7f789" cx="315.24" cy="91.83" r="9.38" />
      <circle cx="315.24" cy="91.83" r="7.99" />
      <circle fill="#f7f789" cx="287.54" cy="91.83" r="9.38" />
      <circle cx="287.54" cy="91.83" r="7.99" />
      <path
        fill="#070707"
        d="M303.18,111a11.05,11.05,0,0,1-.12,3.77,6.08,6.08,0,0,1-1.82,3.48,3.41,3.41,0,0,1-2,.77,4.38,4.38,0,0,1-2-.4,8.8,8.8,0,0,1-3-2.27,16.82,16.82,0,0,0,3.3,1.53,3.14,3.14,0,0,0,3.06-.41,6.39,6.39,0,0,0,1.71-2.91A28.38,28.38,0,0,0,303.18,111Z"
      />
      <path
        fill="#070707"
        d="M303.18,111a28.38,28.38,0,0,0,.87,3.57,6.39,6.39,0,0,0,1.71,2.91,3.14,3.14,0,0,0,3.06.41,16.82,16.82,0,0,0,3.3-1.53,8.8,8.8,0,0,1-3,2.27,4.38,4.38,0,0,1-2,.4,3.41,3.41,0,0,1-2-.77,6.08,6.08,0,0,1-1.82-3.48A11.05,11.05,0,0,1,303.18,111Z"
      />
    </svg>
  )
}

export default IconDeliveryCat
