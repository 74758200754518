import React from 'react'

const IconHotlineCat = (props) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={props.width}
      height={props.height}
      viewBox="0 0 500 500"
      aria-labelledby="title"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="117.16"
          y1="178.22"
          x2="188.32"
          y2="178.22"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#665391" />
          <stop offset="1" stopColor="#4f4f4f" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="294.22"
          y1="162.84"
          x2="359.13"
          y2="162.84"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <title id="title">Cat on the hotline</title>
      <path
        fill="#9e9e9e"
        d="M370.83,223.27c-24.65-43.92-79.83-63.8-129.44-55.13q-2.71.47-5.42,1.05c-50.56,10.83-93.9,28.89-108.35,86.68S120.4,360.61,254,418.4c93.9-25.28,133.63-97.51,133.63-126.41C387.66,266.71,378.49,236.91,370.83,223.27Z"
      />
      <path
        fill="#1e1e1e"
        d="M237.39,243.32H259a2.87,2.87,0,0,1,1.79,5.11L251,256.3a2.87,2.87,0,0,1-3.39.15l-11.8-7.86A2.87,2.87,0,0,1,237.39,243.32Z"
      />
      <path d="M237.39,243.32l8.31-.07,8.31-.18,4.15-.09c.36,0,.67,0,1.06,0a3,3,0,0,1,1.14.25,3.11,3.11,0,0,1,1.66,1.62,3.29,3.29,0,0,1,.18,2.33,3.68,3.68,0,0,1-1.37,1.85l-3.22,2.62L251,256.78a3.23,3.23,0,0,1-2.27.5,3.29,3.29,0,0,1-1.11-.38c-.36-.21-.59-.4-.89-.6l-3.4-2.38-6.81-4.76a5.47,5.47,0,0,1-1.59-1.34,2.91,2.91,0,0,1,2.43-4.52Zm0,0a2.85,2.85,0,0,0-2.81,2.51,2.81,2.81,0,0,0,.48,1.94,5.36,5.36,0,0,0,1.59,1.28l7,4.46,3.51,2.23a3.68,3.68,0,0,0,1.71.77,2.55,2.55,0,0,0,1.69-.44l6.42-5.22,3.27-2.57a2.75,2.75,0,0,0,1.1-1.39,2.52,2.52,0,0,0-1.3-3,4.4,4.4,0,0,0-1.88-.26l-4.15-.09-8.31-.18Z" />
      <path d="M327.94,230.81a15.71,15.71,0,0,1-1,6.25,16.16,16.16,0,0,1-30.15,0,15.71,15.71,0,0,1-1-6.25,16.52,16.52,0,0,0,5,11.1,16,16,0,0,0,22.19,0A16.51,16.51,0,0,0,327.94,230.81Z" />
      <path d="M213.62,230.81a15.71,15.71,0,0,1-1,6.25,16.16,16.16,0,0,1-30.15,0,15.71,15.71,0,0,1-1-6.25,16.52,16.52,0,0,0,5,11.1,16,16,0,0,0,22.19,0A16.51,16.51,0,0,0,213.62,230.81Z" />
      <path
        fill="#f49595"
        d="M188.32,184.59s-5.22,8.15-13,18.11c-12.32,16-31,36.69-44.76,36.15-22.33-.89-24.15-81.27-13.43-84.85S188.32,184.59,188.32,184.59Z"
      />
      <path
        fill="url(#linear-gradient)"
        d="M188.32,184.59s-5.22,8.15-13,18.11c-12.15-16.33-36.47-40.55-58.2-48.69C127.88,150.43,188.32,184.59,188.32,184.59Z"
      />
      <path
        fill="#f49595"
        d="M294.22,176.17s6.53,7.14,15.86,15.64c14.87,13.67,36.84,30.86,50.27,28,21.85-4.69,9.95-84.2-1.22-85.89S294.22,176.17,294.22,176.17Z"
      />
      <path
        fill="url(#linear-gradient-2)"
        d="M294.22,176.17s6.53,7.14,15.86,15.64c9.19-18.16,29-46.17,49-57.9C348,132.22,294.22,176.17,294.22,176.17Z"
      />
      <path d="M324.87,264.81a14.42,14.42,0,0,1,5.29-3.21,25.51,25.51,0,0,1,6.12-1.23,50.39,50.39,0,0,1,6.22-.09c2.06.07,4.13.18,6.2.39a39.87,39.87,0,0,1,12.06,3.06,26.85,26.85,0,0,1,5.34,3.22,20.5,20.5,0,0,1,4.23,4.52,22.42,22.42,0,0,0-4.53-4.13,26.55,26.55,0,0,0-5.35-2.91,41.61,41.61,0,0,0-11.86-2.78,88.4,88.4,0,0,0-12.26-.53,25.28,25.28,0,0,0-6,.93A15.19,15.19,0,0,0,324.87,264.81Z" />
      <path d="M325.65,275.86a16.19,16.19,0,0,1,5.14-2.09,29.86,29.86,0,0,1,5.54-.62c1.86-.06,3.71,0,5.55.1s3.69.19,5.54.38a28.24,28.24,0,0,1,10.73,3,18.19,18.19,0,0,1,4.44,3.41,13.24,13.24,0,0,1,2.87,4.74,14.08,14.08,0,0,0-3.21-4.4,17.74,17.74,0,0,0-4.45-3.07,30.71,30.71,0,0,0-10.48-2.72c-1.82-.2-3.65-.32-5.48-.43s-3.67-.23-5.5-.28a29.91,29.91,0,0,0-5.46.33A17.61,17.61,0,0,0,325.65,275.86Z" />
      <path d="M328.24,285.25a13.39,13.39,0,0,1,3.74-.68,17.4,17.4,0,0,1,3.82.33,21,21,0,0,1,7.09,2.91,32.43,32.43,0,0,1,5.86,4.89q1.31,1.37,2.54,2.81c.8,1,1.6,1.95,2.36,3-.92-.86-1.8-1.76-2.72-2.63s-1.79-1.76-2.72-2.6a43.18,43.18,0,0,0-5.86-4.6,24.29,24.29,0,0,0-6.72-3A17.36,17.36,0,0,0,328.24,285.25Z" />
      <path d="M181.52,267.4a6,6,0,0,0-1.87-2.05,9.63,9.63,0,0,0-2.52-1.16,35.61,35.61,0,0,0-5.51-1.1c-3.73-.58-7.46-1.18-11.2-1.52a67.09,67.09,0,0,0-11.24-.32,41.38,41.38,0,0,0-5.58.7,40.33,40.33,0,0,0-5.47,1.41,35,35,0,0,1,11-2.87,60.34,60.34,0,0,1,11.41.1q5.67.64,11.24,1.76a35.2,35.2,0,0,1,5.54,1.4,10,10,0,0,1,2.51,1.41A5.59,5.59,0,0,1,181.52,267.4Z" />
      <path d="M177.51,276.52a42.4,42.4,0,0,0-10.6-.44,43.65,43.65,0,0,0-19.82,6.78l-2.18,1.51L142.83,286l-2,1.77q-.92,1-1.87,1.91.83-1.05,1.68-2.09l1.85-2,2-1.79,2.14-1.65a37.91,37.91,0,0,1,20.2-6.91h2.7l2.69.18,2.66.41Q176.21,276.19,177.51,276.52Z" />
      <path d="M178.34,286.17a55.73,55.73,0,0,0-7.85,1.39A51.47,51.47,0,0,0,156,293.77l-1.66,1.08L152.7,296l-1.59,1.21q-.76.65-1.55,1.27.67-.74,1.37-1.47l1.46-1.39,1.55-1.29,1.61-1.22a38.22,38.22,0,0,1,14.77-6.33A34,34,0,0,1,178.34,286.17Z" />
      <path d="M248.09,259c-.36,7.31-1,15.2-6.91,20.35-5,4.35-11.77,5.93-18.19,6.51-.64.06-.64,1.06,0,1,6.68-.6,13.74-2.25,18.9-6.8,6.07-5.35,6.82-13.45,7.2-21.05,0-.64-1-.64-1,0Z" />
      <path d="M249,257.06a24.09,24.09,0,0,0,6.57,21.17,20.51,20.51,0,0,0,21.09,4.89c.6-.21.34-1.18-.27-1a19.52,19.52,0,0,1-20.12-4.63,23,23,0,0,1-6.31-20.2c.1-.63-.86-.9-1-.27Z" />
      <path d="M309.56,247.92a10.69,10.69,0,0,0,5.18,7,.5.5,0,0,0,.5-.86,9.71,9.71,0,0,1-4.72-6.38c-.14-.63-1.11-.36-1,.27Z" />
      <path d="M319.45,246.7a15.56,15.56,0,0,0,5.59,5.46.5.5,0,0,0,.5-.86,14.51,14.51,0,0,1-5.23-5.1.5.5,0,0,0-.86.5Z" />
      <path d="M325.5,241.44a83.53,83.53,0,0,0,8.42,5c.57.3,1.08-.57.5-.86a83.53,83.53,0,0,1-8.42-5c-.53-.36-1,.5-.5.86Z" />
      <path d="M195.14,247.73a10,10,0,0,1-3.67,5.41c-.51.38,0,1.25.5.86a11.14,11.14,0,0,0,4.13-6,.5.5,0,0,0-1-.27Z" />
      <path d="M187.3,244.41a17.51,17.51,0,0,1-4.72,4.76c-.53.36,0,1.23.5.86a18.81,18.81,0,0,0,5.08-5.11c.36-.54-.51-1-.86-.5Z" />
      <path d="M183.84,239.46a23.61,23.61,0,0,1-7,4.22c-.59.23-.34,1.2.27,1a25.07,25.07,0,0,0,7.44-4.48c.49-.42-.22-1.13-.71-.71Z" />
      <ellipse fill="#013668" cx="86.56" cy="249.01" rx="29.56" ry="31.34" />
      <path d="M129.38,273.53c0,7.92-7.88,14.35-17.6,14.35H98.58c-9.72,0-17.6-6.43-17.6-14.35V223.31c0-7.93,7.88-14.35,17.6-14.35h13.2c9.72,0,17.6,6.42,17.6,14.35Z" />
      <ellipse fill="#013668" cx="413.44" cy="249.01" rx="29.56" ry="31.34" />
      <path d="M370.62,273.53c0,7.92,7.88,14.35,17.6,14.35h13.2c9.72,0,17.6-6.43,17.6-14.35V223.31c0-7.93-7.88-14.35-17.6-14.35h-13.2c-9.72,0-17.6,6.42-17.6,14.35Z" />
      <path
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="4.75px"
        d="M97.42,233.07c0-83.65,67.19-151.47,150.07-151.47s150.07,67.82,150.07,151.47"
      />
      <path
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="3.71px"
        d="M391.43,271.48c0,40.12-41,72.65-91.66,72.65"
      />
      <ellipse cx="290.57" cy="345.47" rx="15.42" ry="11.67" />
    </svg>
  )
}

export default IconHotlineCat
